import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { Link, NavLink } from 'react-router-dom';
import { FeatherIconLayout } from "../icons/FeatherIconLayout";
var l = require('react-router-dom').Link


class Sidebar extends React.Component {
  render() {

    const admin_email = localStorage.getItem('admin_id');
    return (
      <div className="div-2 col-md-2">
        <div className="overlap-2">
            <NavLink to="/Dashboard" activeClassName="active">
              <div className="element-navigations-menus">
                <div className="element-navigations-menus-wrapper">
                  <div className="list-item-wrapper" style={{top:"25px"}}>
                    <div className="list-item">Dashboard</div>
                  </div>
                </div>
              </div>
            </NavLink>
            
          {/* </div> */}
           {admin_email === "66b05c0f246335294a9db975" ? (
            <> 
          <div className="overlap-4">

          
            <NavLink to="/Customers" activeClassName="active">
              <div className="element-navigations-menus-22">
                <div className="text-wrapper-3">User Management</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users-2.svg').default}
                />

              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
              </div>
             
            </NavLink>
          </div>

          
          <NavLink to="/Products" activeClassName="active">
            <div className="element-navigations-menus-5">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Products</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Groups" activeClassName="active">
            <div className="element-navigations-menus-6">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Categories</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Subgroups" activeClassName="active">
            <div className="element-navigations-menus-7">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Sub Categories</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Brands" activeClassName="active">
            <div className="element-navigations-menus-8">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Brands</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Savecart" activeClassName="active">
            <div className="element-navigations-menus-9">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Save for Later</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Likecart" activeClassName="active">
            <div className="element-navigations-menus-10">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Like Cart</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Addcart" activeClassName="active">
            <div className="element-navigations-menus-11">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Add to Cart</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Orderplaced" activeClassName="active">
            <div className="element-navigations-menus-12">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Order placed</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img 
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
         

          <NavLink to="/Reports" activeClassName="active">
            <div className="element-navigations-menus-13">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Products Report</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Reports1" activeClassName="active">
            <div className="element-navigations-menus-14">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Stores Report</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img 
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Reports2" activeClassName="active">
            <div className="element-navigations-menus-15">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Revenue Report</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>

          <NavLink to="/Offers" activeClassName="active">
            <div className="element-navigations-menus-16">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Offers</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink> 


          <NavLink to="/Coupons" activeClassName="active">
            <div className="element-navigations-menus-17">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Coupons</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink> 

          </>
          ) : (
            <>

<NavLink to="/Customers" activeClassName="active">
              <div className="element-navigations-menus-2">
                <div className="text-wrapper-3">User Management</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users-2.svg').default}
                />

              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
              </div>
             
            </NavLink>

<NavLink to="/Products" activeClassName="active">
            <div className="element-navigations-menus-5">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Products</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Groups" activeClassName="active">
            <div className="element-navigations-menus-6">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Categories</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Subgroups" activeClassName="active">
            <div className="element-navigations-menus-7">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Sub Categories</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Brands" activeClassName="active">
            <div className="element-navigations-menus-8">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Brands</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          {/* <NavLink to="/Savecart" activeClassName="active">
            <div className="element-navigations-menus-9">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Save for Later</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Likecart" activeClassName="active">
            <div className="element-navigations-menus-10">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Like Cart</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
          <NavLink to="/Addcart" activeClassName="active">
            <div className="element-navigations-menus-11">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Add to Cart</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink> */}
           {/* <NavLink to="/Orderplaced" activeClassName="active">
            <div className="element-navigations-menus-9">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Order placed</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
         </NavLink> */}
         
       
          <NavLink to="/Reports" activeClassName="active">
            <div className="element-navigations-menus-9">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Products Report</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink>
         
          <NavLink to="/Reports2" activeClassName="active">
            <div className="element-navigations-menus-10">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Revenue Report</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink> 

          <NavLink to="/Offers" activeClassName="active">
            <div className="element-navigations-menus-11">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Offers</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink> 


          <NavLink to="/Coupons" activeClassName="active">
            <div className="element-navigations-menus-12">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Coupons</div>
                <img
                  className="img-2"
                  alt="Feather icon users"
                  src={require('../assets/img/feather-icon-users.svg').default}
                />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
              />
            </div>
          </NavLink> 



            </>
          )}
        </div>
        <div className="img-wrapper">
          <img
            className="frame_img"
            alt="Frame"
            src={require('../assets/img/frame1.svg').default}
          />
        </div>
      </div>
    );
  }
}

export default Sidebar;
