import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "../components/withRouter";
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Header from "../components/Header";
import Footer from "../components/Footer";
import SidebarOne from "../components/sidebar1";
import InnerHeader from "../components/InnerHeader";
import DatatableProducts from "./datatableProducts";

class Products extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      prescription: 'no',
      search: ''
    };
    
  }

  componentDidMount() {
    this.callgetproductsapi(this.state.prescription);
  }



  callgetproductsapi(pres, search) {
      // axios({
      //   method: 'get',
      //   url: this.state.api_url+'/admingetProducts',
      // }).then(res => {
      //   const data = res.data;
      //   this.setState({
      //     data: data.products,
      //   });
      // });
  }

  handleButtonClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("product_id", id);
    this.props.history.push('/viewProduct');
  };

  render() {
    const columns = [
      {
        name: 'Product Name',
        selector: row => row.product_name,
      },
      {
        name: 'Brand Name',
        selector: row => row.brand_name,
      },
      {
        name: 'Price',
        selector: row => row.price,
      },
      {
        name: 'Product Image',
        selector: row => row.product_image,
        cell: row => <img height="84px" width="56px" alt={row.name} src={row.product_image} />,
      },
      {
        name: 'Action',
        selector: row => row.action,
        cell: (row) => (
          <button
            className="tableBtn"
            onClick={(e) => this.handleButtonClick(e, row.id)}
          >
            View
          </button>
        ),
      },
    ];

    return (
      <div className="productsa">
        <SidebarOne />
        <InnerHeader />
        <hr></hr>
        <div>
          
          <DatatableProducts />
        </div>
        
        
      </div>
    );
  }
}

export default withRouter(Products);
