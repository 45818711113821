import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios"; // Import axios library
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-responsive-modal';


// import "../assets/css/product.css"; // Import custom CSS file for styling

const DatatableAssign = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [filterText, setFilterText] = useState("");
  const [sortField, setSortField] = useState(null);
  const [sortDirection, setSortDirection] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [openModal, setOpenModal] = useState(false); // State for modal visibility
  const [pic, setPic] = useState([]);
  const [filename, setFilename] = useState([]);
  const [sortColumn, setSortColumn] = useState(null);
  const [allSelected, setAllSelected] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      const api_url = localStorage.getItem('api_url');
      const admin_id = localStorage.getItem('admin_id');
      const res = await axios.get(api_url + '/getorderassign/'+admin_id);
      const data = await res.data.cartItems;
      setData(data);
      setFilteredData(data);
    };

    fetchData();
  }, []);

  const handleFilterChange = (e) => {
    const value = e.target.value || "";
    setFilterText(value);
    filterData(value, sortField, sortDirection);
  };

  const handleSelectAll = (isChecked) => {
    setAllSelected(isChecked);
    if (isChecked) {
      setSelectedRows(filteredData);
    } else {
      setSelectedRows([]);
    }
  };

  const handleSort = (column, direction) => {
    setSortColumn(column.selector);
    setSortDirection(direction);
    filterData(filterText, column.selector, direction);
  };
  
  const filterData = (filterValue, sortField, sortDirection) => {
    const filteredItems = data.filter((item) =>
      Object.keys(item).some(
        (key) =>
          key !== "id" &&
          item[key] &&
          item[key].toString().toLowerCase().includes(filterValue.toLowerCase())
      )
    );
  
    if (sortField && sortDirection) {
      filteredItems.sort((a, b) => {
        const fieldA = (a[sortField] || "").toString().toLowerCase();
        const fieldB = (b[sortField] || "").toString().toLowerCase();
        return sortDirection === "asc" ? fieldA.localeCompare(fieldB) : fieldB.localeCompare(fieldA);
      });
    }
  
    setFilteredData(filteredItems);
  };
  

  const handleRowSelected = (isChecked, row) => {
    setSelectedRows((prevSelectedRows) => {
      if (isChecked) {
        return [...prevSelectedRows, row];
      } else {
        return prevSelectedRows.filter((selectedRow) => selectedRow.id !== row.id);
      }
    });
  };

  const navigate = useNavigate();


  
  const exportSelectedRows = () => {
    console.log('ddd');
    const selectedRowsData = selectedRows.map(row => {
      return {
        'payment Id':row.pay_success,
        'Order Id':row.order_id,
        'customer Name': row.username,
        'Store name': row.store_name,
        'Agent Name': row.agent_name,
        'Pincode':row.pincode,
      };
    });
  
    const worksheet = XLSX.utils.json_to_sheet(selectedRowsData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "SelectedRows");
    XLSX.writeFile(workbook, "orderplaced.xlsx");
  };

  const exportSelectedRows1 = () => {
    console.log('ddd');
    setOpenModal(true); // Open the modal
  };
//   const handleDivClick = (id) => {
//     localStorage.setItem("cart_id", id);
//     navigate("/Orderassign", { replace: true });
//   };

 

  const customHeader = (column) => {
    if (column.filter) {
      return (
        <input
          type="text"
          placeholder={`Filter ${column.name}`}
          onChange={(e) => handleFilterChange(e, column.selector)}
          value={filterText}
          style={{ width: "100%" }} // Adjust the width as needed
        />
      );
    }
    return null;
  };

  const web_url = localStorage.getItem('web_url');

  const columns = [
    {
        name:  <input
        type="checkbox"
        onChange={(e) => handleSelectAll(e.target.checked)}
        checked={allSelected}
      />,
      cell: (row) => (
        <input
  type="checkbox"
  onChange={(e) => handleRowSelected(e.target.checked, row)}
  checked={selectedRows.map((selectedRow) => selectedRow.order_id).includes(row.order_id)}
/>
      ),
    },
    {
      name: 'Payment Id',
      selector: (row) => (row && row.pay_success) || '',
      sortable: true,
      filter: true,
    },
    {
      name: 'Order Id',
      selector: (row) => (row && row.order_id) || '',
      sortable: true,
      filter: true,
    },
    {
      name: 'Customer Name',
      // selector: row => row.item_sku,
      selector: (row) => (row && row.username) || '',
      sortable: true,
      filter: true,
    },
    {
      name: 'Store Name',
      // selector: row => row.barcode_value,
      selector: (row) => (row && row.store_name) || '',
      sortable: true,
      filter: true,
    },
    
    {
      name: 'Agent Name',
      // selector: row => '₹'+row.price+'.00',
      selector: (row) => (row && row.agent_name) || '',

      sortable: true,
      filter: true,
    },
    {
      name: 'Pincode',
      // selector: row => row.product_name,
      selector: (row) => (row && row.pincode) || '',
      sortable: true,
      filter: true,
    },
    {
        name: 'Status',
        // selector: row => row.product_name,
        selector: (row) => (row && row.status) || '',
        sortable: true,
        filter: true,
      }
    
  ];

  return (
    <div>
        <div className="pro_buttons">
            
            <button className="export_tem" onClick={exportSelectedRows}>EXPORT</button>
            </div>
        <p className="produc_de">Order Assign Details</p>
    <div className="data_margin">
      
    <DataTable
  columns={columns}
  data={filteredData}
  pagination
  paginationPerPage={5}
  paginationRowsPerPageOptions={[5, 10, 15,50,100,500,1000,10000]}
  highlightOnHover
  // onSort={handleSort} // Pass handleSort function here
  sortServer={false}

  // selectableRows
  // selectableRowsHighlight
  // selectableRowsNoSelectAll
  // selectableRowsVisibleOnly
  customStyles={{
    headCells: {
      style: {
        fontWeight: "bold",
      },
    },
    rows: {
      style: {
        "&:nth-of-type(odd)": {
          backgroundColor: "#f3f3f3",
        },
      },
    },
  }}
  customHeader={customHeader}
/>

    </div>

    </div>
  );
};

export default DatatableAssign;
