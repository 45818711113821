import React from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";

class viewOffer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      product: [],
      offer: {},
      id: localStorage.getItem("id"),
      showAlert: false,
      errors: {},
      suc_cls: '',
      mediaVisible: false,
      specsVisible: false,
      hierarchyVisible: false,
      attributesVisible: false
    };
  }

  toggleVisibility = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key]
    }));
  };

  componentDidMount() {
    const { api_url, id } = this.state;
    axios.get(`${api_url}/getoffer/${id}`).then(res => {
      const data = res.data;
      this.setState({
        offer: data.offer,
        product: data.product_names
      });
    }).catch(error => {
      console.error("Error fetching offer data:", error);
    });
  }

  moveeditproduct = () => {
    this.props.navigate("/editOffer");
  };

  render() {
    const { offer, product } = this.state;

    return (
      <div className="view_pro" style={{ display: 'flex' }}>
        <div>
          <SidebarOne />
        </div>

        <div className="pr_names">
          <div className="pro_names" style={{ display: 'flex' }}>
            <p className="p_name">Offers</p>
            <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
          </div>

          <hr />

          <div className="product_detail">
            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Offer Name</p>
                <div className="img_detils_cat">
                  <img
                    className="itme_img"
                    title="edit Group"
                    onClick={this.moveeditproduct}
                    src={require("../assets/images/edit.png")}
                    alt="edit"
                  />
                </div>
              </div>
              <input
                readOnly
                type="text"
                className="item_input_cat"
                name="offer_name"
                value={offer.offer_name || ''}
              />
            </div>
            <br />

              <div className="item_details">
              <div style={{ display: 'flex' }}>
              <p className="item_name">Product Names</p>
              </div>
              {product && product.map((productName, index) => (
              <p key={index} className="">{productName.product_name}</p>
              ))}
              </div>
            <br />

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Offer Percentage</p>
              </div>
              <input
                readOnly
                type="number"
                className="item_input_cat"
                name="offer"
                value={offer.offer}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(viewOffer);
