import React from 'react';
import ReactDOM from 'react-dom/client';

class Footer extends React.Component {
  render() {
    return <div className="footer">
    <p className="support-help-center">
      Support&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Help
      Center&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terms of Service
    </p>
  </div>;
  }
}

export default Footer;





