import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const RevenueReports = () => {
  const [data, setData] = useState([]);
  const [fromDate, setFromDate] = useState(new Date());
  const [toDate, setToDate] = useState(new Date());
  const api_url = localStorage.getItem('api_url');
  const store_id = localStorage.getItem('store_id') || ""; // Ensure store_id is a string, default to empty string if not set

  useEffect(() => {
    fetchData();
  }, [fromDate, toDate]);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${api_url}/getSalesProductData2`, {
        params: {
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          ...(store_id && { store_id }) // Conditionally add store_id if it's not empty
        },
      });
      setData(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  const exportExcel = async () => {
    try {
      const response = await axios.get(`${api_url}/exportExcel`, {
        params: {
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          ...(store_id && { store_id }) // Conditionally add store_id if it's not empty
        },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'revenue_report.xlsx');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting Excel:', error);
    }
  };

  const exportPDF = async () => {
    try {
      const response = await axios.get(`${api_url}/exportPDF`, {
        params: {
          fromDate: fromDate.toISOString(),
          toDate: toDate.toISOString(),
          ...(store_id && { store_id }) // Conditionally add store_id if it's not empty
        },
        responseType: 'blob'
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'revenue_report.pdf');
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error exporting PDF:', error);
    }
  };

  return (
    <div className="data_margin">
      <div className="date-picker-container">
        <label>From Date </label>
        <DatePicker 
          selected={fromDate} 
          onChange={(date) => setFromDate(date)} 
          maxDate={new Date()} // Prevent future dates
          placeholderText="Select From Date"
        />
      </div>
      <div className="date-picker-container">
        <label>To Date </label>
        <DatePicker 
          selected={toDate} 
          onChange={(date) => setToDate(date)} 
          maxDate={new Date()} // Prevent future dates
          placeholderText="Select To Date"
        />
      </div>
      <div>
        <button className="reve_bn" onClick={fetchData}>Fetch Data</button>
        <button className="reve_bn" onClick={exportExcel}>Export to Excel</button>
        <button className="reve_bn" onClick={exportPDF}>Export to PDF</button>
      </div>
      <DataTable
        className="data-table"
        title="Top Selling Products Report"
        columns={[
          { name: 'Product Name', selector: row => row.product_name, sortable: true },
          // { name: 'Brand Name', selector: row => row.brand_name, sortable: true },
          { name: 'Price', selector: row => row.price, sortable: true },
          { name: 'Quantity', selector: row => row.quantity, sortable: true },
        ]}
        data={data}
        pagination
      />
    </div>
  );
};

export default RevenueReports;
