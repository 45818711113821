import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { Link, NavLink  } from 'react-router-dom';

class Sidebar extends React.Component {
  render() {
    return <div className="div-2 col-md-2">
    <div className="overlap-8">
      <div className="element-navigations-menus">
        <div className="element-navigations-menus-2">
          <div className="text-wrapper-12">Dashboard</div>
          {/* <FeatherIconLayout
            className="feather-icon-layout-instance"
            featherIconLayout="/img/feather-icon-layout-2.svg"
          /> */}
        </div>
      </div>
      <div className="overlap-9">
      
        <div className="element-navigations-menus">
        <NavLink  to="/Dashboard"  activeClassName="active">
          <div className="div-wrapper">
            <div className="overlap-group-5">
           
              {/* <div className="list-item-wrapper"> */}
                <div className="list-item">Dashboard</div>
              {/* </div> */}
              <img className="img-2" alt="Feather icon layout" src={require("../assets/img/feather-icon-layout-1.svg").default} />
             
            </div>
          </div>
          </NavLink>
        </div>
      
        <NavLink  to="/Stores"  activeClassName="active">
        <div className="element-navigations-menus">
          <div className="element-navigations-menus-3">
         
            <div className="text-wrapper-12">Stores</div>
            <img className="img-2" alt="Ic store" src={require("../assets/img/ic-store-48px-1.svg").default} />
          
          </div>
          <img
            className="feather-icon-chevron-5"
            alt="Feather icon chevron"
            src={require("../assets/img/feather-icon-chevron-down.svg").default}
          />
        </div>
        </NavLink>
      </div>
      <div className="overlap-10">
      <NavLink  to="/Customers"  activeClassName="active">
        <div className="element-navigations-menus">
       
          <div className="text-wrapper-12">Customers</div>
          <img className="img-2" alt="Feather icon users" src={require("../assets/img/feather-icon-users-2.svg").default} />
       
        </div>
        <img
          className="feather-icon-chevron-5"
          alt="Feather icon chevron"
          src={require("../assets/img/feather-icon-chevron-down.svg").default}
        />
        </NavLink>
      </div>
      <div className="element-navigations-menus-4">
        <div className="element-navigations-menus-3">
        <NavLink  to="/Agents"  activeClassName="active">
          <div className="text-wrapper-12">Agents</div>
          <img className="img-2" alt="Feather icon users" src={require("../assets/img/feather-icon-users-1.svg").default} />
          </NavLink>
        </div>
        
        <img
          className="feather-icon-chevron-5"
          alt="Feather icon chevron"
          src={require("../assets/img/feather-icon-chevron-down.svg").default}
        />
      </div>
      <div className="element-navigations-menus-5">
        <div className="element-navigations-menus-3">
        <NavLink  to="/Products"  activeClassName="active">
          <div className="text-wrapper-12">Products</div>
          <img className="img-2" alt="Feather icon users" src={require("../assets/img/feather-icon-users.svg").default} />
        </NavLink>
        </div>
        <img
          className="feather-icon-chevron-5"
          alt="Feather icon chevron"
          src={require("../assets/img/feather-icon-chevron-down.svg").default}
        />
      </div>
      <div className="element-navigations-menus-6">
        <div className="element-navigations-menus-3">
        <NavLink  to="/Orders"  activeClassName="active">
          <div className="text-wrapper-12">Orders</div>
          <img className="img-2" alt="Feather icon users" src={require("../assets/img/icon-bell-copy.svg").default} />
        </NavLink>
        </div>
        <img
          className="feather-icon-chevron-5"
          alt="Feather icon chevron"
          src={require("../assets/img/feather-icon-chevron-down.svg").default}
        />
      </div>
    </div>
    <div className="frame-wrapper">
      <img className="frame" alt="Frame" src={require("../assets/img/frame.svg").default} />
    </div>
  </div>;
  }
}

export default Sidebar;




