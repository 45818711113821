import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "../components/withRouter";
import SidebarOne from "../components/sidebar1";
import { useNavigate } from 'react-router-dom';

function Viewusers() {
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');
  const user_id = localStorage.getItem('user_id');
  const [formData, setFormData] = useState({
    fullname: "",
    email: '',
    mobile: '',
    companyname: '',
    productpercentage: '',
    user_id: "",
  });

  useEffect(() => {
    const fetchCards = async () => {
      try {
        const res = await axios.get(`${api_url}/getProfile/${user_id}`);
        const data = res.data;

        setCards(data.profile);

        // Update the formData state with fetched data
        setFormData({
          ...formData,
          fullname: data.profile.fullname,
          email: data.profile.email,
          mobile: data.profile.mobile,
          // companyname: data.profile.companyname, // Ensure this data is available in the response
          productpercentage: "5", // Ensure this data is available in the response
          user_id: user_id,
        });
      } catch (error) {
        console.error("Error fetching card data:", error);
      }
    };

    fetchCards();
  }, []); // Empty dependency array means this useEffect runs once after the initial render

  // Update form data state when input or select box changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  // Pass form data to updateuserandaddress function
  const updateuserandaddress = () => {
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }

    axios({
      method: "post",
      url: `${api_url}/updateuserdetails_admin`,
      data: data
    })
      .then((res) => {
        // Add any success handling code here
        alert("Users Updated successfully");
        navigate("/Customers", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error updating user details:", error);
        // Add any error handling code here
      });
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <p className="p_name">User Management </p>
        <hr></hr>
        <div className="product_detail">

          <br></br>
          <table style={{ borderCollapse: 'collapse', width: '100%', marginTop: '20px' }}>
            <tbody>
              <tr>
                <td className="tab_itmes">
                  Name of the Company :
                  <input readOnly name="fullname" placeholder="Name" type="text" className="item_input" value={formData.fullname} onChange={handleChange} />
                </td>
                <td className="tab_itmes">
                  Percentage for products :
                  <input readOnly name="productpercentage" type="text" className="item_input" value={formData.productpercentage} onChange={handleChange} />
                </td>
              </tr>
              <tr>
                <td className="tab_itmes">
                  Email Address :
                  <input readOnly name="email" type="text" className="item_input" value={formData.email} onChange={handleChange} />
                </td>
                <td className="tab_itmes">
                  Phone Number :
                  <input readOnly name="mobile" type="text" className="item_input" value={formData.mobile} onChange={handleChange} />
                </td>
              </tr>
            </tbody>
          </table>
          <div className="img_detils1">
            <button className="btn update_bt" style={{ border: "none", background: "#16887D" }} onClick={updateuserandaddress}> Update </button>
          </div>
        </div>
        <br></br>
      </div>
    </div>
  ); 
}

export default withRouter(Viewusers);
