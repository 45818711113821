import React from "react";
import { Modal } from 'react-responsive-modal';
import 'react-responsive-modal/styles.css';
import DataTable from 'react-data-table-component';
import Header from "../components/Header"
import Footer from "../components/Footer"
import SidebarOne from "../components/sidebar1"
import InnerHeader from "../components/InnerHeader"
import axios from 'axios';
import '../assets/css/alertCss.css';
import '../assets/css/modal.css';

class Stores extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      search: '',
      suc_cls: '',
      errors: {},
      openModal: false,
      pic: '',
      fileName: ''
    };
    this.onChange_prescription = this.onChange_prescription.bind(this);
    this.onChange_search = this.onChange_search.bind(this);
    this.handleButtonClick = this.handleButtonClick.bind(this);
    this.handleImport = this.handleImport.bind(this);
    this.fileUploadclick = this.fileUploadclick.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleFileUpload = this.handleFileUpload.bind(this);
    this.fileUpload = this.fileUpload.bind(this);

  }
  onCloseModal = () => {
    this.setState({ openModal: false })
  }
  handleFileUpload(e) {
    document.getElementById("fileID").click();
  }
  fileUploadclick(e) {
    this.setState({
      pic: e.target.files[0],
      fileName: e.target.files[0].name
    });
  }
  onChange_search(e) {
    this.setState({
      search: e.target.value
    });
  }
  onChange_prescription(e) {
    this.setState({
      prescription: e.target.value
    });
  }
  handleDelete(e, store_id) {
    const choice = window.confirm("Are you sure you want to delete the Store?")
    if (choice) {
      let errors = {};
      axios({
        method: 'get',
        url: this.state.api_url + '/deleteStore/' + store_id,

      }).then(res => {
        const message = res.data.message;
        errors["message"] = message;
        this.setState({
          errors: errors,
          suc_cls: 'successMsg floatingAlerts1'
        });
        setTimeout(() => {
          this.setState({
            suc_cls: 'successMsghide'
          });
          this.props.navigate('/Stores');
        }, 2000);
      });
    }
  }
  fileUpload() {
    var storeimp = new FormData();
    storeimp.append('pic', this.state.pic);

    let errors = {};
    axios({
      method: 'post',
      url: this.state.api_url + '/importStores',
      data: storeimp,
    }).then(res => {
      const message = res.data.message;
      errors["message"] = message;
      this.setState({
        errors: errors,
        openModal: false,
        suc_cls: 'successMsg floatingAlerts1'
      });
      setTimeout(() => {
        this.setState({
          suc_cls: 'successMsghide'
        });
        window.location.reload(true);
      }, 5000);
    })
  }
  handleImport() {
    this.setState({ openModal: true });
    return;
  }
  callgetstoresapi() {
    axios({
      method: 'get',
      url: this.state.api_url + '/admingetStores',
    }).then(res => {
      const data = res.data;
      this.setState({
        data: data.stores,
      });
    });
  }
  handleButtonClick(e, id) {
    e.preventDefault();
    localStorage.setItem("store_id", id);
    this.props.navigate('/editStore');
  };
  componentDidMount() {
    this.callgetstoresapi();
  }

  render() {
    const columns = [
      {
        name: 'Store Name',
        selector: row => row.store_name,
      },
      {
        name: 'Address',
        selector: row => row.address,
      },
      {
        name: 'Order history',
        selector: row => "",
      },

      {
        name: 'Documents',
        selector: row => "",
      },

      {
        name: 'Action',
        selector: row => row.action,
        cell: (row) => (
          <div>
           
            <button
              className="btn btn-outline btn-xs"
              onClick={(e) => this.handleDelete(e, row.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ];

    return (
      <div className="products">
        <Header />
        <SidebarOne />

        <InnerHeader />
        <hr />
        <p className="p_name_store">Stores</p>
        <br></br>
        <div className="frame-3">
          <div className="frame-5" style={{ left: "5px" }}>
            <input type="text" placeholder="Search..." class="searchtxt" value={this.state.search} onChange={this.onChange_search} />
            <button type="submit" class="element-icon-button" style={{ padding: 0 }} onclick={this.callgetstoresapi(this.state.prescription)}> <img
              alt="Element icon button"
              src={require('../assets/img/4-icon-button-1-default-1-default.svg').default}
            /></button>
          </div>
          <button class="element-button-text-2" onClick={this.handleImport} style={{ left: "921px" }}>
            <img class="icons-ic-outline" src={require('../assets/img/importic.svg').default} />
            <div class="button-text" style={{ "color": "#7c3a8e" }}>IMPORT</div>
          </button>
        </div>
        <div className="overlap-6">
          <div className={this.state.suc_cls}>{this.state.errors.message}</div>
          <div className="group-4">
            <DataTable
              columns={columns}
              data={this.state.data}
              pagination
            />
          </div>
        </div>
        <Modal open={this.state.openModal} onClose={this.onCloseModal} id="fileupload">
          <div class="container">
            {/* <div class="card"> */}
              <h3>Import</h3>
              <hr />
              <div class="drop_box">
                { (this.state.pic == '') ?
                  <div>
                     <img className="itme_img2" src={require("../assets/images/under_areo.png")}/>
                    <header>
                      <h4 className="choose_import">Choose the file to be imported</h4>
                    </header>
                    <p className="choose_import1">Only xls,xlsx amd csv formats are supported</p>
                    <span className="choose_import2">Maximum upload file size is 5MB</span>
                    <br></br>
                    <input type="file" hidden accept=".csv" id="fileID" style={{ display: "none" }} onChange={this.fileUploadclick} />
                    <button class="btn choose_import3" onClick={this.handleFileUpload}>Upload File</button>
                    <br></br>
                    <a className="down_sample" href="javascript:;">Download sample template for import</a>
                  </div> : '' }
                { (this.state.pic == '') ?
                  <form action="" id="uploadForm" method="post" style={{ display: "none" }}>
                    <div class="form">
                      <h4>{this.state.fileName}</h4>
                      <button class="btn">Upload</button>
                    </div>
                  </form> : <form action="" id="uploadForm" method="post">
                    <div class="form">
                      <h4>{this.state.fileName}</h4>
                      <button class="btn" type="button" onClick={this.fileUpload}>Upload</button>
                    </div>

                    
                  </form> }
                  
              </div>
              
            {/* </div> */}
          </div>
        </Modal>
      </div>
    );
  }
}

export default Stores;
