import React from "react";
import { Link, NavLink  } from 'react-router-dom';
import { Frame } from "../components/Frame";
import { FrameWrapper } from "../components/FrameWrapper";
import { FeatherIconLayout } from "../icons/FeatherIconLayout";
import { IcChevronLeft48Px } from "../icons/IcChevronLeft48Px";
import { IcChevronRight48Px } from "../icons/IcChevronRight48Px";
import { IcFirstPage48Px } from "../icons/IcFirstPage48Px";
import { IcLastPage48Px1 } from "../icons/IcLastPage48Px1";
import DataTable from 'react-data-table-component';
import Header from "../components/Header"
import Footer from "../components/Footer"
import Sidebar from "../components/sidebar"
import SidebarOne from "../components/sidebar1"
import InnerHeader from "../components/InnerHeader"
import axios from 'axios';



class Editstore extends React.Component { 
  constructor(props) {
    super(props);       
        this.savestore = this.savestore.bind(this);     
        this.onChange_flatno = this.onChange_flatno.bind(this);
        this.onChange_area = this.onChange_area.bind(this);     
        this.onChange_landmark = this.onChange_landmark.bind(this);     
        this.onChange_pincode = this.onChange_pincode.bind(this);     
        this.onChange_phoneno = this.onChange_phoneno.bind(this); 
        this.onChange_sub_phoneno = this.onChange_sub_phoneno.bind(this);  
        this.onChange_email = this.onChange_email.bind(this);       
        this.handleFileUpload = this.handleFileUpload.bind(this);     
        this.state = {  
            api_url:localStorage.getItem('api_url'),
            flatno:'',area:'',
            landmark:'',pincode:'',phoneno:'9876543210',suc_cls:'',
            errors:  {},store:{},store_name:'',
            store_id:localStorage.getItem("store_id")
        }
    
  }
  handleFileUpload(e){
    this.setState({
        pic:e.target.files[0]
    });
}
onChange_store_name(e){
  this.setState({
    store_name:e.target.value
  });
}
onChange_flatno(e){
    this.setState({
        flatno:e.target.value
    });
}
onChange_area(e){
    this.setState({
        area:e.target.value
    });
}
onChange_landmark(e){
    this.setState({
        landmark:e.target.value
    });
}
onChange_pincode(e){
    this.setState({
        pincode:e.target.value
    });
} onChange_phoneno(e){
    this.setState({
        phoneno:e.target.value
    });
} onChange_sub_phoneno(e){
    this.setState({
        sub_phonenoe:e.target.value
    });
} onChange_email(e){
    this.setState({
        email:e.target.value
    });
} 
savestore(){
    
    let errors = {};
    let pattern = new RegExp(/^[1-9][0-9]{5}$/i)
    if (!pattern.test(this.state.pincode)) { 
        errors["message"] = "Please enter valid pincode";  
    }
    let phonepattern  = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
    if (!phonepattern.test(this.state.phoneno)) { 
        errors["message"] = "Please enter valid phoneno";  
    }   
    if(errors["message"]){
        this.setState({
            errors: errors,
            suc_cls: 'errorMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'errorMsghide'
                });
               
              }, 5000);  
              errors = {};
           return; 
            } 
       

    // var store = new FormData();
    // store.append('store_id', this.state.store_id);
    // store.append('flatno', this.state.flatno);
    // store.append('area', this.state.area);
    // store.append('landmark', this.state.landmark);
    // store.append('pincode', this.state.pincode);
    // store.append('phoneno', this.state.phoneno);   

    let store = {
      "store_id":this.state.store_id,
      'flatno': this.state.flatno,
      'area': this.state.area,
      'landmark': this.state.landmark,
      'pincode': this.state.pincode,
      'phoneno': this.state.phoneno,
      'store_name': this.state.store_name,
      'email': this.state.email,
    };
   

     axios({
        method: "post",
        url: this.state.api_url+'/saveStore',
        data: store        			       
             
    }).then(res => {
        const message = res.data.message;       
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'successMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'successMsghide'
                });
              }, 5000);    
        
        })
            

}
  componentDidMount() {    
   
    //this.callgetstoresapi(this.state.prescription); 
    axios({
        method: 'get',
        url: this.state.api_url+'/admingetStore/'+this.state.store_id,               
        
      }).then(res => {
        const data = res.data;    
        console.log("editstore");
        console.log(data) ; 
        this.setState({                         
                store: data.store,
                flatno: data.store.flatno, 
                area: data.store.area,  
                phoneno: data.store.phoneno,
                sub_phoneno: data.store.sub_phoneno,
                landmark: data.store.landmark,
                pincode: data.store.pincode,   
                store_name:data.store.store_name           
              });
      });
}
    render() {
       
   // const externalImage = this.state.store.store_image;

        return (
          
            <div className="EditProduct">
              <div className="div">
              
                {/* { <Header /> } */}
                
                { <Footer />   }
                { <SidebarOne />   }
                 {/* <div className="div-2">
                  <div className="overlap-2">
                    <div className="element-navigations-menus">
                      <div className="element-navigations-menus-2">
                        <div className="text-wrapper-3">Dashboard</div>
                        <FeatherIconLayout className="img-2" />
                      </div>
                    </div>
                    <div className="overlap-3">
                      <div className="element-navigations-menus">
                        <div className="element-navigations-menus-wrapper">
                          <div className="list-item-wrapper">
                            <div className="list-item">Dashboard</div>
                          </div>
                        </div>
                      </div>
                      <div className="element-navigations-menus">
                        <div className="element-navigations-menus-3">
                          <div className="text-wrapper-3">Stores</div>
                          <img className="img-2" alt="Ic store" src={require('../assets/img/ic-store-48px-1.svg').default} />
                        </div>
                        <img
                          className="feather-icon-chevron"
                          alt="Feather icon chevron"
                          src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                        />
                      </div>
                    </div>
                    <div className="overlap-4">
                      <div className="element-navigations-menus">
                        <div className="text-wrapper-3">Customers</div>
                        <img className="img-2" alt="Feather icon users" src={require('../assets/img/feather-icon-users-2.svg').default} />
                      </div>
                      <img
                        className="feather-icon-chevron"
                        alt="Feather icon chevron"
                        src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                      />
                    </div>
                    <div className="element-navigations-menus-4">
                      <div className="element-navigations-menus-3">
                        <div className="text-wrapper-3">Agents</div>
                        <img className="img-2" alt="Feather icon users" src={require('../assets/img/feather-icon-users-1.svg').default} />
                      </div>
                      <img
                        className="feather-icon-chevron"
                        alt="Feather icon chevron"
                        src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                      />
                    </div>
                    <Link className="element-navigations-menus-5" to="/stores">
                      <div className="element-navigations-menus-3">
                        <div className="overlap-group-2">
                          <div className="text-wrapper-3">stores</div>
                          <img className="img-2" alt="Feather icon users" src={require('../assets/img/feather-icon-users.svg').default} />
                        </div>
                      </div>
                      <img
                        className="feather-icon-chevron"
                        alt="Feather icon chevron"
                        src={require('../assets/img/feather-icon-chevron-down-2.svg').default}
                      />
                    </Link>
                  </div>
                  <div className="frame-wrapper">
                    <img className="frame" alt="Frame" src={require('../assets/img/frame.svg').default} />
                  </div>
                </div>  */}
               
                <div className="element-navigations-wrapper row">
                  <div className="element-navigations">
                    <div className="link">Stores</div>
                  </div>
                </div>  
                <img
                    className="feather-icon-chevron-2"
                    alt="Feather icon chevron"
                    src="/assets/img/feather-icon-chevron-right-1.svg"
                    />
                    <div className="link-2">Edit store</div>              
                {<InnerHeader />}
                <div className="rectangle" />
        <div className="frame-2">
           <div className="frame-3">
           <input type="text" id="store_name" placeholder="Store name"  value={this.state.store_name} onChange={this.onChange_store_name} className="container" />
             
          </div> 
          <button className="element-button-text" style={{left:"982px"}}>
            <button className="button" onClick={this.savestore}>SAVE</button>
          </button>
          <NavLink to="/stores">
          <button className="element-button-text-2" style={{left:"870px"}}>
            <button className="button-text-3">CANCEL</button>
          </button>
          </NavLink>
        </div>
        {/* <div className="overlap-wrapper">
          <div className="overlap-6">
            <div className="cards-header">
              <div className="portlet-title">store Images</div>
              <img className="ic-expand-less" alt="Ic expand less" src="/assets/img/ic-expand-less-48px-1.svg" />
            </div>
            <div className="table-header-6">Images &amp; Videos</div>
            <div className="cards-body" />
           
            <div className="group-wrapper">
            <input type="file" name="pic" className="group-4" onChange={this.handleFileUpload} style={{left:"59px",width:"183px"}} />
              </div>
          </div>
        </div> */}
        {/* <div className="group-5">
          <div className="overlap-7">
            <div className="cards-header">
              <div className="portlet-title">Prescription</div>
              <img className="ic-expand-less" alt="Ic expand less" src="/assets/img/ic-expand-less-48px.svg" />
            </div>
            <div className="table-header-7">Images, Documents &amp; Videos</div>
            <div className="cards-body-2">
              <div className="overlap-group-4">
                <img className="group-6" alt="Group" src="/assets/img/group-1546.png" />
                <div className="table-header-wrapper">
                  <div className="table-header-8"> DEFAULT</div>
                </div>
              </div>
            </div>
            <div className="img-wrapper">
              <img className="group-7" alt="Group" src="/assets/img/group-1561.png" />
            </div>
          </div>
        </div> */}
        <div className="group-8">
        <div className={this.state.suc_cls}>{this.state.errors.message}</div>
          <div className="overlap-8">
          <img className="feather-icon-type-5" alt="Feather icon type" src="/assets/img/feather-icon-type-1.svg" />          
          <img className="feather-icon-type-4" alt="Feather icon type" src="/assets/img/feather-icon-type-1.svg" />
            <img className="feather-icon-type" alt="Feather icon type" src="/assets/img/feather-icon-type-1.svg" />
            {/* <img className="feather-icon-hash" alt="Feather icon hash" src="/assets/img/feather-icon-hash.svg" /> */}
            <div className="element">Flat No</div>
            <div className="group-9">
              <div className="overlap-group-5">
                {/* <div className="element-2">Pylmukti Tablet</div>
                <img className="container" alt="Container" src="/assets/img/container-5.svg" /> */}
                <input type="text" id="flatno"  value={this.state.flatno} onChange={this.onChange_flatno} className="container" />
             
              </div>
            </div>
            <div className="group-10">
              <div className="overlap-group-5">
              <input type="text" id="area"  value={this.state.area} onChange={this.onChange_area} className="container" />
            
                {/* <div className="element-2">Sri Sri</div>
                <img className="container" alt="Container" src="/assets/img/container-4.svg" /> */}
              </div>
            </div>
            <div className="group-11">
              <div className="overlap-group-5">
                {/* <div className="element-2">170</div>
                <img className="container" alt="Container" src="/assets/img/container-3.svg" /> */}
                 <input type="text" id="landmark"  value={this.state.landmark} onChange={this.onChange_landmark} className="container" /> 
           
              </div>
            </div>
            <div className="group-12">
              <div className="overlap-group-5">
               
                 <input type="text" id="email"  value={this.state.email} onChange={this.onChange_email} className="container" />
           
              </div>
            </div>
            <div className="group-13">
              <div className="overlap-group-5">
              <input type="text" id="phoneno"  value={this.state.phoneno} onChange={this.onChange_phoneno} className="container" />
             
                {/* <img
                  className="feather-icon-chevron-4"
                  alt="Feather icon chevron"
                  src="/assets/img/feather-icon-chevron-right.svg"
                /> */}
              </div>
            </div>
            <div className="group-14">
              <div className="overlap-group-5">
              <input type="text" id="pincode"  value={this.state.pincode} onChange={this.onChange_pincode} className="container" />
             
                
              </div>
            </div>
            <div className="element-3">Landmark</div>
            <div className="element-4">Area</div>  
            <img className="feather-icon-command" alt="Feather icon command" src="/assets/img/feather-icon-command.svg" />
            <div className="element-5">Email</div>          
            <div className="element-6">Pincode</div>
            <div className="element-7">Phone no</div>
            <img className="feather-icon-type-2" alt="Feather icon type" src="/assets/img/feather-icon-type-1.svg" />
            <img className="feather-icon-type-3" alt="Feather icon type" src="/assets/img/feather-icon-type.svg" />
            <img className="line" alt="Line" src="/assets/img/line-67.svg" />
            <img className="line-2" alt="Line" src="/assets/img/line-74.svg" />
            <img className="line-3" alt="Line" src="/assets/img/line-68.svg" />
            <img className="line-4" alt="Line" src="/assets/img/line-75.svg" />
            <img className="line-5" alt="Line" src="/assets/img/line-70.svg" />
            <img className="line-6" alt="Line" src="/assets/img/line-69.svg" />
          </div>
        </div>
              </div>
            </div>
          );
    }
}

export default Editstore;
