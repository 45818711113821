import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "./withRouter";
import axios from 'axios';
import SidebarOne from "./sidebar1";
import InnerHeader from "./InnerHeader";
import Orderstore from "./Orderstore";

class OrderDelivery extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      prescription: 'no',
      search: ''
    };
    
  }

  componentDidMount() {
  }


  render() {
    return (
      <div className="productsa">
        <SidebarOne />
        <InnerHeader />
        <hr></hr>
        <div className="delivery_order">
           <p>Product selected on stores and delivery agents </p>
           <hr />
          <Orderstore />
            
        </div>
      </div>
    );
  }
}

export default withRouter(OrderDelivery);
