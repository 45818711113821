import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function EditCoupon(props) {
  const [coupon_code, setCouponCode] = useState('');
  const [amount_upto, setAmountUpto] = useState('');
  const [offer, setOffer] = useState('');
  const [valid_date, setValidDate] = useState('');
  const [data1, setData1] = useState([]);
  const navigate = useNavigate();
  const api_url = localStorage.getItem('api_url');
  const id = localStorage.getItem('id');
  const coupon_id = id;
  const [validationMessages, setValidationMessages] = useState({
    coupon_code: "",
    amount_upto: "",
    offer: "",
    valid_date: ""
  });

  const updateOfferDetails = async (event) => {
    event.preventDefault();

    if (validateForm()) {
      const formData = {
        coupon_id,
        coupon_code,
        amount_upto,
        offer,
        valid_date
      };

      try {
        await axios.post(`${api_url}/updatecoupondetails`, formData);
        alert("Coupon updated successfully");
        navigate("/Coupons", { replace: true });
      } catch (error) {
        console.error("Error updating coupon:", error);
      }
    }
  };

  useEffect(() => {
    const fetchCouponDetails = async () => {
      try {
        const res = await axios.get(`${api_url}/getcoupon1/${id}`);
        const data = res.data;
        setCouponCode(data.offer.coupon_code);
        setAmountUpto(data.offer.amount_upto);
        setOffer(data.offer.offer);
        setValidDate(data.offer.valid_date);
      } catch (error) {
        console.error("Error fetching coupon data:", error);
      }
    };
    fetchCouponDetails();
  }, [api_url, id]);

  const validateForm = () => {
    let isValid = true;
    const errors = {
      coupon_code: "",
      amount_upto: "",
      offer: "",
      valid_date: ""
    };

    if (!coupon_code.trim()) {
      errors.coupon_code = "Coupon code is required.";
      isValid = false;
    }

    if (!amount_upto.trim()) {
      errors.amount_upto = "Amount is required.";
      isValid = false;
    }

    if (!offer.trim()) {
      errors.offer = "Offer percentage is required.";
      isValid = false;
    } else if (isNaN(offer) || parseFloat(offer) <= 0 || parseFloat(offer) > 100) {
      errors.offer = "Offer percentage must be a number between 1 and 100.";
      isValid = false;
    }

    if (!valid_date) {
      errors.valid_date = "Valid date is required.";
      isValid = false;
    }

    setValidationMessages(errors);
    return isValid;
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Coupons </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>

        <hr />

        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Coupon Code</p>
              <button className="itme_img11" onClick={updateOfferDetails}>Update</button>
            </div>
            <input
              type="text"
              className="item_input_cat"
              name="coupon_code"
              value={coupon_code}
              onChange={(e) => setCouponCode(e.target.value)}
              
            />
            {validationMessages.coupon_code && <Alert severity="error">{validationMessages.coupon_code}</Alert>}
          </div>
          <br />

          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Get amount above offer</p>
            </div>
            <input
              type="text"
              className="item_input_cat"
              name="amount_upto"
              value={amount_upto}
              onChange={(e) => setAmountUpto(e.target.value)}
              
            />
            {validationMessages.amount_upto && <Alert severity="error">{validationMessages.amount_upto}</Alert>}
          </div>
          <br />

          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Offer Percentage</p>
            </div>
            <input
              type="number"
              className="item_input_cat"
              name="offer"
              value={offer}
              onChange={(e) => setOffer(e.target.value)}
              
            />
            {validationMessages.offer && <Alert severity="error">{validationMessages.offer}</Alert>}
          </div>
          <br />

          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Valid Date</p>
            </div>
            <input
              type="date"
              className="item_input_cat"
              name="valid_date"
              value={valid_date}
              onChange={(e) => setValidDate(e.target.value)}
              
            />
            {validationMessages.valid_date && <Alert severity="error">{validationMessages.valid_date}</Alert>}
          </div>
          <br />
        </div>
      </div>
    </div>
  );
}

export default withRouter(EditCoupon);
