import React, { useState, useEffect } from "react";
import axios from 'axios';

// Sample product hierarchy data






// Recursive component to render categories and their subcategories/products
const CategoryTree = ({ categories, expandedCategories, setExpandedCategories }) => {
  const toggleCategory = (categoryId) => {
    setExpandedCategories((prevExpanded) => {
      if (prevExpanded.includes(categoryId)) {
        return prevExpanded.filter((id) => id !== categoryId);
      } else {
        return [...prevExpanded, categoryId];
      }
    });
  };

  return (
    <ul style={{'margin-top': '10px'}}>
      {categories.map((category) => (
        <li key={category.id} style={{'margin-top': '10px'}}>
          <button className='exp_btn11' onClick={() => toggleCategory(category.id)}>
            {expandedCategories.includes(category.id) ? 'Collapse' : 'Expand'} {category.name}
          </button>
          {expandedCategories.includes(category.id) && (
            <CategoryTree
              categories={category.subcategories}
              expandedCategories={expandedCategories}
              setExpandedCategories={setExpandedCategories}
            />
          )}
          {expandedCategories.includes(category.id) && (
            <ul style={{'margin-top': '10px'}}>
              {category.subcategories.map((subcategory) => (
                <li key={subcategory.id} style={{'margin-top': '10px'}}>
                  <button className='exp_btn11' onClick={() => toggleCategory(subcategory.id)}>
                    {expandedCategories.includes(subcategory.id) ? 'Collapse' : 'Expand'} {subcategory.name}
                  </button>
                  {expandedCategories.includes(subcategory.id) && (
                    <ul>
                      {subcategory.products.map((product) => (
                        <li key={product.id}>{product.name}</li>
                      ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          )}
        </li>
      ))}
    </ul>
  );
};

// Main component
const ProductHierarchy = () => {
  const [expandedCategories, setExpandedCategories] = useState([]);
  const [cards, setCards] = useState([]);
  const [cards1, setCards1] = useState([]);
  const [cards2, setCards2] = useState([]);

  const api_url = localStorage.getItem('api_url');
  const product_id = localStorage.getItem('product_id');
const data=[];
useEffect(() => {
  const fetchCards = async () => {
    try {
      // const response = await axios.get("/cards"); 
      // setCards(response.data); 
      axios({
          method: 'get',
          url:api_url+'/getcategory_sub_product/'+product_id,               
          
        }).then(res => {
          const data = res.data;
         // console.log(data);
         setCards(data.product);
        });



    } catch (error) {
      console.error("Error fetching card data:", error);
    }
  };
  fetchCards();
}, []); 

const categories = [
  {
    id: 1,
    name: cards?.category || "",
    subcategories: [
      {
        id: 11,
        name: cards?.sub_category || "",
        products: [
          {
            id: 111,
            name: cards?.product_name || ""
          }
        ]
      }
    ]
  }
];

  return (
    <div>
      <button className='exp_btn'  onClick={() => setExpandedCategories(categories.map((category) => category.id))}>
        Expand All
      </button>
      <button className='coll_btn' onClick={() => setExpandedCategories([])}>Collapse All</button>
      <br></br>
      <CategoryTree
        categories={categories}
        expandedCategories={expandedCategories}
        setExpandedCategories={setExpandedCategories}
      />
    </div>
  );
};

export default ProductHierarchy;
