import React from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import { Modal, Button } from 'react-bootstrap'
import { Frame } from "../components/Frame";
import { FrameWrapper } from "../components/FrameWrapper";
import { FeatherIconLayout } from "../icons/FeatherIconLayout";
import { IcChevronLeft48Px } from "../icons/IcChevronLeft48Px";
import { IcChevronRight48Px } from "../icons/IcChevronRight48Px";
import { IcFirstPage48Px } from "../icons/IcFirstPage48Px";
import { IcLastPage48Px1 } from "../icons/IcLastPage48Px1";
import DataTable from 'react-data-table-component';
import Header from "../components/Header"
import Footer from "../components/Footer"
import Sidebar from "../components/sidebar"
import SidebarOne from "../components/sidebar1"
import InnerHeader from "../components/InnerHeader"
import {withRouter} from "../components/withRouter"
import axios from 'axios';
import '../assets/css/alertCss.css';

//import '../assets/css/product.css';

class AgentAcceptOrder extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
        data:[],
        prescription:'no',search:'',show:'',hide:'',
        errors:  {},order_id:window.location.href.split('/')[5],agent_id:window.location.href.split('/')[6],userinfo:{},cart_details:[],storeinfo:{}
    };     
    this.onChange_prescription = this.onChange_prescription.bind(this); 
    this.onChange_search = this.onChange_search.bind(this); 
    this.handleButtonClick = this.handleButtonClick.bind(this);     
    this.submitForm = this.submitForm.bind(this);
    this.handleDelete = this.handleDelete.bind(this);    
  }
  onChange_search(e){
    //console.log("zd",e.target.value);
    this.setState({
        search:e.target.value
    });   
    //this.callgetcustomersapi(e.target.value);  
  }
  onChange_prescription(e){
    //console.log("zd",e.target.value);
    this.setState({
        prescription:e.target.value
    });   
    //this.callgetcustomersapi(e.target.value);  
  }
  handleDelete(e,store_id){
    console.log(store_id);
    const choice = window.confirm(
      "Are you sure you want to delete the Store?"
    )
    if(choice){
      let errors = {};
      axios({
        method: 'get',
        url: this.state.api_url+'/deleteStore/'+store_id,               
        
      }).then(res => {
        const message = res.data.message;       
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'successMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'successMsghide'
                });
                this.props.navigate('/customers');
              }, 2000);    
      });
    }
  }
  submitForm(){
    
    let errors = {};
    axios({
        method: 'get',
        url: this.state.api_url+'/agentAcceptOrder/'+this.state.order_id+'/'+this.state.agent_id,        
             
    }).then(res => {
        document.querySelector(".main").style.display="none";
        document.querySelector(".order").style.display="block";        
        })
  }
  callgetOrderDetails(){
    //console.log(this.state.prescription); 
    let url;      
    url = this.state.api_url+'/callgetOrderDetails/'+this.state.order_id+'/'+this.state.agent_id;
    axios({
      method: 'get',
      url: url,             
      
    }).then(res => {
      const data = res.data;
      console.log(data);
      this.setState({                         
              userinfo: data.userinfo, 
              cart_details: data.cart_details,     
              storeinfo: data.storeinfo,        
            });
    });
}
 handleButtonClick (e, id){
  e.preventDefault();
  //return <Link to='/viewProduct'/>;
  localStorage.setItem("store_id",id);
  this.props.navigate('/editStore');
  //this.props.history.push("/login");
 // console.log("Row Id", id);
};
  componentDidMount() {     
   this.callgetOrderDetails(); 
}

    render() {
     // navigate = useNavigate();
  
      const columns = [
        {
            name: 'Customer Name',
            selector: row => row.name,
            
        },
        {
            name: 'Address',
            selector: row => row.address,
        },        
        {
            name: 'Order id',
            selector: row => row.order_id,
        },
        {
            name: 'Transaction',
            selector: row => row.transaction,
        },
        {
            name: 'Total amount',
            selector: row => row.amount,
        },
        {
            name: 'Order status',
            selector: row => row.status,
        },
        // {
        //   name: 'Action',
        //   selector: row => row.action,
        //   cell: (row) => (
        //     <div>
        //     <button
        //         className="btn btn-outline btn-xs"
        //         onClick={(e) => this.handleButtonClick(e, row.id)}
        //     >
        //         Edit
        //     </button>
        //     <button
        //         className="btn btn-outline btn-xs"
        //         onClick={(e) => this.handleDelete(e, row.id)}
        //     >
        //         Delete
        //     </button>
        //     </div>
            
        // ),
        // },
      
    

    ];    
   
        return (
            <div className="accept">

<div class="main">
	<h1>Order Request!</h1>
	<form action="javascript:void(0);">
    
		<fieldset>
		<legend> Customer Info:</legend>
		<label for="name">Name:</label><br/>
	<input type="text" id="name" name="user_name" value={this.state.userinfo.name} readOnly/><br/>
		<label for="mail">Email:</label><br/>
	<input type="email" id="mail" name="user_email" value={this.state.userinfo.email} readOnly /><br/>
		<label for="phone">Phone:</label><br/>
	<input type="tel" id="phone" name="user_tel" value={this.state.userinfo.phone_number} readOnly /><br/>
		<label for="address">Address:</label><br/>
		<textarea readOnly value={this.state.userinfo.building +"\n"+this.state.userinfo.area+"\n"+this.state.userinfo.pincode}
    >  
    </textarea>
	</fieldset>
  <fieldset>
		<legend> Store Info:</legend>
		<label for="name">Name:</label><br/>
	<input type="text" id="sname" name="user_name" value={this.state.storeinfo.name} readOnly/><br/>
		<label for="mail">Email:</label><br/>
	<input type="email" id="smail" name="user_email" value={this.state.storeinfo.email} readOnly /><br/>
		<label for="phone">Phone:</label><br/>
	<input type="tel" id="sphone" name="user_tel" value={this.state.storeinfo.phoneno} readOnly /><br/>
		<label for="address">Address:</label><br/>
		<textarea readOnly value={this.state.storeinfo.address}
    >  
    </textarea>
	</fieldset>
	<fieldset>
  <table width="40%" id="alignment" > 
                <tr>  
                <th>Product name</th> 
                <th></th>
                <th>Quantity</th>
                <th></th>
                <th>Price(Rs.)</th>
                </tr> 
                {this.state.cart_details.map(cart => (
                                       

                      <tr>
                      <td>{cart.product_name}</td> 
                      <td></td>
                      <td>{cart.quantity}</td> 
                      <td></td>
                      <td>{cart.price}</td>
                      </tr> 
                                      ))}
                
                
                </table>
</fieldset>
<input class="button" type="submit" value="Accept Order!" onClick={this.submitForm} />
</form>
</div>
<div class="order">
	<h1>Thanks for accept the  order!</h1>
	<img src="/assets/img/thanku.png"/>
</div>


            </div>
          );
    }
}

export default withRouter(AgentAcceptOrder);
