import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { Link  } from 'react-router-dom';
import {withRouter} from "./withRouter"
import '../assets/css/style.css';
import axios from 'axios';

class Signup extends React.Component {
  constructor(props) {
    super(props); 
    this.state = {
      api_url:localStorage.getItem('api_url'),
        data:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},openModal : false,pic:'',fileName:'',email:'',password:''
    };     
    this.loginfun = this.loginfun.bind(this); 
    this.onChange_email = this.onChange_email.bind(this);    
    this.onChange_password = this.onChange_password.bind(this);    
    this.signuppage = this.signuppage.bind(this);   
    
    this.onChange_fullname = this.onChange_fullname.bind(this);    

    this.onChange_mobile = this.onChange_mobile.bind(this);    

 
  }

  signuppage() {
    this.props.navigate('/Login');
  }
  onChange_email(e){    
    this.setState({
        email:e.target.value
    }); 
  }

  onChange_fullname(e){    
    this.setState({
        fullname:e.target.value
    }); 
  }

  onChange_mobile(e){    
    this.setState({
        mobile:e.target.value
    }); 
  }
  onChange_password(e){    
    this.setState({
        password:e.target.value
    }); 
  }
  loginfun(e){
    var signin = new FormData();
    signin.set('email', this.state.email);
    signin.set('password', this.state.password);     
    
    axios({
      method: 'post',
      url: this.state.api_url+'/adminSettings',
      data: {'email':this.state.email,'fullname':this.state.fullname,'mobile':this.state.mobile,'password':this.state.password} 
        
            })
    .then(res => {
     
      if(res.data.status == "false"){
        let errors = {};
        const message = res.data.message;       
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'errorMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'errorMsghide'
                });
              }, 3000);  

      }else{

      
          alert("Your account has been created, please login to your account");
          this.props.navigate('/Login');
        
        
      }
    })
   
  }
  render() {
    return <div className="login">
    <div className="div">
      <div className="group">
        <p className="text-wrapper-sign">Register your account</p>
        <p className="p">
        <div className={this.state.suc_cls}>{this.state.errors.message}</div>
        </p>        
        <div className="group-2_sign">
          <div className="input-filled_sign">
            <div className="overlap1">
            <input className="input-BG" id="password" value={this.state.password} onChange={this.onChange_password} type="password" maxLength={15} minLength={6} placeholder="Password" required />										
              <div className="feather-icon-eye-off">
                <div className="shape">
                </div>
              </div>
            </div>
          </div>
          <div className="overlap-wrapper">
            <div className="overlap1">
            <input required className="input-BG" type="text" id="email" placeholder="Full Name" value={this.state.fullname} onChange={this.onChange_fullname} />
            </div>
          </div>
<br></br>
          <div className="overlap-wrapper1">
            <div className="overlap2">
            <input required className="input-BG" type="email" id="email" placeholder="Email" value={this.state.email} onChange={this.onChange_email} />
            </div>
          </div>
          <br></br>

          <div className="overlap-wrapper2">
            <div className="overlap3">
            <input required className="input-BG" type="text" id="email" placeholder="Mobile Number" value={this.state.mobile} onChange={this.onChange_mobile} />
            </div>
          </div>
          <br></br>

         
          <div className="primary1">            
             <button  onClick={this.loginfun}  className="continue_sign" style={{border: "none",background:"#3ec63b"}}>  
             Sign Up        
            </button> 
          </div>
          <p className='signin_admin'>If you already signup. Click <span className='resign_admin' onClick={this.signuppage}>Sign In</span></p>
        </div>
      </div>
      <img  src={require('../assets/img/frame1.svg').default} className="frame" alt="Frame"  />
    </div>
  </div>;
  }
}

export default withRouter(Signup);



