import React from 'react';


class Header extends React.Component {
  render() {
    return <div className="header">
    {/* <img className="ic-error-outline" alt="Ic error outline" src={require("../assets/img/ic-error-outline-48px.svg").default} /> */}
    {/* <div className="group">
      <div className="overlap-group">
        <img className="img" alt="Icon bell" src={require("../assets/img/icon-bell.svg").default} />
        <div className="element-status-indicators">
          <div className="button-text">3</div>
        </div>
      </div>
    </div> */}
    {/* <div className="icon">
      <div className="overlap-group">
        <img className="img" alt="Icon bell copy" src={require("../assets/img/icon-bell-copy.svg").default} />
        <div className="button-text-wrapper">
          <div className="button-text">7</div>
        </div>
      </div>
    </div> */}
    {/* <div className="element-avatars-circle">
      <div className="overlap">
        <div className="text-wrapper">JJ</div>
      </div>
    </div> */}
    {/* <img className="icon-2" alt="Icon" src="/assets/img/icon.png" /> */}
    {/* <div className="icons">
      <div className="text-wrapper-2">Search topic</div>
      <img className="ic-search" alt="Ic search" src={require("../assets/img/ic-search-48px.svg").default} />
    </div> */}
  </div>;
  }
}

export default Header;




