import React from 'react';
import ReactDOM from 'react-dom/client'; 
import { Link, NavLink  } from 'react-router-dom';
import { FeatherIconLayout1 } from "../icons/FeatherIconLayout1";
import SidebarOne from "../components/sidebar1"
import InnerHeader from "../components/InnerHeader"
//import '../assets/css/product1.css';
import '../assets/css/alertCss.css';

import axios from 'axios';

class AddProduct extends React.Component {
    constructor(props) {
        super(props);       
        this.saveProduct = this.saveProduct.bind(this);     
        this.onChange_product_name = this.onChange_product_name.bind(this);
        this.onChange_brand_name = this.onChange_brand_name.bind(this);     
        this.onChange_price = this.onChange_price.bind(this);     
        this.onChange_qty = this.onChange_qty.bind(this);     
        this.onChange_category = this.onChange_category.bind(this); 
        this.onChange_sub_category = this.onChange_sub_category.bind(this);  
        this.onChange_prescription = this.onChange_prescription.bind(this);       
        this.handleFileUpload = this.handleFileUpload.bind(this);     
        this.state = {  
            api_url:localStorage.getItem('api_url'),         
            category:'Body',sub_category:'skin care',
            product_name:'',brand_name:'',
            price:'',quantity:'',prescription:'0',suc_cls:'',
            errors:  {},pic:'',categories:[],brands:[]
        }
        
    }    
    handleFileUpload(e){
        this.setState({
            pic:e.target.files[0]
        });
    }
    onChange_product_name(e){
        this.setState({
            product_name:e.target.value
        });
    }
    onChange_brand_name(e){
        this.setState({
            brand_name:e.target.value
        });
    }
    onChange_price(e){
        this.setState({
            price:e.target.value
        });
    }
    onChange_qty(e){
        this.setState({
            quantity:e.target.value
        });
    } onChange_category(e){
        this.setState({
            category:e.target.value
        });
    } onChange_sub_category(e){
        this.setState({
            sub_categorye:e.target.value
        });
    } onChange_prescription(e){
        this.setState({
            prescription:e.target.value
        });
    } 
    saveProduct(){
        
      let errors = {};
      let pattern = new RegExp(/^[1-9][0-9]{5}$/i)
      if (this.state.product_name == '') { 
          errors["message"] = "Please enter product name";  
      }
      if (this.state.quantity == '') { 
        errors["message"] = "Please enter quantity";  
    }
    if (this.state.price == '') { 
      errors["message"] = "Please enter price";  
  }
  if (this.state.pic == '') { 
    errors["message"] = "Please upload product image";  
}
      // let phonepattern  = new RegExp(/^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/i);
      // if (!phonepattern.test(this.state.phoneno)) { 
      //     errors["message"] = "Please enter valid phoneno";  
      // }   
      if(errors["message"]){
          this.setState({
              errors: errors,
              suc_cls: 'errorMsg floatingAlerts1'
              });
              setTimeout(() => {
                  this.setState({
                      suc_cls: 'errorMsghide'
                  });
                 
                }, 5000);  
                errors = {};
             return; 
              } 

        var product = new FormData();
        product.append('product_name', this.state.product_name);
        product.append('brand_name', this.state.brand_name);
        product.append('price', this.state.price);
        product.append('quantity', this.state.quantity);
        product.append('category', this.state.category);
        product.append('sub_category', this.state.sub_category);  
        product.append('pic', this.state.pic);  
        product.append('prescription', this.state.prescription ); 
       

         axios({
            method: 'post',
            url: this.state.api_url+'/addProduct',
            data: product  
                 
        }).then(res => {
            const message = res.data.message;
           // console.log(res.data);
            errors["message"] = message;
            if(res.data.status == "true"){
              this.setState({
                errors: errors,
                suc_cls: 'successMsg floatingAlerts1'
                });
                setTimeout(() => {
                    this.setState({
                        suc_cls: 'successMsghide'
                    });
                  }, 5000);
            }else{
              this.setState({
                errors: errors,
                suc_cls: 'errorMsg floatingAlerts1'
                });
                setTimeout(() => {
                    this.setState({
                        suc_cls: 'errorMsghide'
                    });
                  }, 5000);
            }             
            
            })               
   
    }
    componentDidMount() {    
   
      //this.callgetproductsapi(this.state.prescription); 
      axios({
          method: 'get',
          url: this.state.api_url+'/getCategories',               
          
        }).then(res => {
          const data = res.data;      
          this.setState({                         
                  categories: data.categories,
                  
                               
                });
        });

        axios({
          method: 'get',
          url: this.state.api_url+'/getBrands',               
          
        }).then(res => {
          const data = res.data;      
          this.setState({                         
                  brands: data.brands,    
                });
        });
  }
  render() {
    return <div className="products1">
    <div className="div">
      {/* <div className="header">
        <img className="ic-error-outline" alt="Ic error outline" src="../assets/img/ic-error-outline-48px.svg" />
        <div className="group">
          <div className="overlap-group">
            <img className="img" alt="Icon bell" src="../assets/img/icon-bell.svg" />
            <div className="element-status-indicators">
              <div className="button-text">3</div>
            </div>
          </div>
        </div>
        <div className="icon">
          <div className="overlap-group">
            <img className="img" alt="Icon bell copy" src="../assets/img/icon-bell-copy.svg" />
            <div className="button-text-wrapper">
              <div className="button-text">7</div>
            </div>
          </div>
        </div>
        <div className="element-avatars-circle">
          <div className="overlap">
            <div className="text-wrapper">JJ</div>
          </div>
        </div>
        <img className="icon-2" alt="Icon" src="../assets/img/icon.png" />
        <div className="icons">
          <div className="text-wrapper-2">Search topic</div>
          <img className="ic-search" alt="Ic search" src="../assets/img/ic-search-48px.svg" />
        </div>
      </div> */}
      <footer className="footer">
        <div className="support-help-center">
          Support&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Help
          Center&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Privacy&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Terms of Service
        </div>
      </footer>
      {/* <div className="dashboard">
        <div className="overlap-2">
          <div className="element-navigations-menus">
            <div className="element-navigations-menus-2">
              <div className="text-wrapper-3">Dashboard</div>
               <FeatherIconLayout1 className="img-2" /> 
            </div>
          </div>
          <div className="overlap-3">
            <div className="element-navigations-menus">
              <div className="element-navigations-menus-wrapper">
                <div className="list-item-wrapper">
                  <div className="list-item">Dashboard</div>
                </div>
              </div>
            </div>
            <div className="element-navigations-menus">
              <div className="element-navigations-menus-3">
                <div className="text-wrapper-3">Stores</div>
                <img className="img-2" alt="Ic store" src="../assets/img/ic-store-48px-1.svg" />
              </div>
              <img
                className="feather-icon-chevron"
                alt="Feather icon chevron"
                src="../assets/img/feather-icon-chevron-down.svg"
              />
            </div>
          </div>
          <div className="overlap-4">
            <div className="element-navigations-menus">
              <div className="text-wrapper-3">Customers</div>
              <img className="img-2" alt="Feather icon users" src="../assets/img/feather-icon-users-2.svg" />
            </div>
            <img
              className="feather-icon-chevron"
              alt="Feather icon chevron"
              src="../assets/img/feather-icon-chevron-down.svg"
            />
          </div>
          <div className="element-navigations-menus-4">
            <div className="element-navigations-menus-3">
              <div className="text-wrapper-3">Agents</div>
              <img className="img-2" alt="Feather icon users" src="../assets/img/feather-icon-users-1.svg" />
            </div>
            <img
              className="feather-icon-chevron"
              alt="Feather icon chevron"
              src="../assets/img/feather-icon-chevron-down.svg"
            />
          </div>
          <Link className="element-navigations-menus-5" to="/products">
            <div className="element-navigations-menus-3">
              <div className="overlap-group-2">
                <div className="text-wrapper-3">Products</div>
                <img className="img-2" alt="Feather icon users" src="../assets/img/feather-icon-users.svg" />
              </div>
            </div>
            <img
              className="feather-icon-chevron"
              alt="Feather icon chevron"
              src="../assets/img/feather-icon-chevron-down.svg"
            />
          </Link>
        </div>
        <div className="frame-wrapper">
          <img className="frame" alt="Frame" src="../assets/img/frame.svg" />
        </div>
      </div> */}
      <SidebarOne/>
      {/* <div class="row"> */}
        <div className="element-navigations-wrapper row">
          <div className="element-navigations">
            <div className="link">Products</div>
          </div>
          <img
          className="feather-icon-chevron-2"  
          alt="Feather icon chevron"
          src="../assets/img/feather-icon-chevron-right-3.svg"
        />
        <div className="link-2">Create</div>
        </div>
        
      {/* </div> */}
      {/* <div className="overlap-5">
        <div className="group-2">
          <div className="table-header">Customers</div>
          <div className="table-header-2">Dashboard</div>
          <div className="div-wrapper">
            <div className="button-text">51</div>
          </div>
          <img className="glyph-mail-trash" alt="Glyph mail trash" src="../assets/img/glyph-mail-trash-2.png" />
          <img className="img-3" alt="Ic poll" src="../assets/img/ic-poll-48px.svg" />
        </div>
        <div className="group-3">
          <div className="table-header-3">Agents</div>
          <div className="table-header-2">Stores</div>
          <div className="element-status-indicators-2">
            <div className="button-text">51</div>
          </div>
          <img className="glyph-mail-trash" alt="Glyph mail trash" src="../assets/img/glyph-mail-trash-1.png" />
          <img className="img-3" alt="Ic store" src="../assets/img/ic-store-48px.svg" />
          <div className="overlap-group-wrapper">
            <div className="overlap-group-3">
              <div className="table-header-4">Products</div>
              <div className="element-status-indicators-3">
                <div className="button-text-2">305</div>
              </div>
              <img className="img-3" alt="Glyph mail trash" src="../assets/img/glyph-mail-trash.png" />
            </div>
          </div>
        </div>
      </div> */}
      <div class="row">
      {<InnerHeader />}      
      <div className="rectangle" />   
      </div>
      <div className="frame-2">
        {/* <div className="frame-3"> */}
        <select name="prescription" id="prescription" class="frame-3" value={this.state.prescription} onChange={this.onChange_prescription} >
  <option value="0" selected>Without Prescription</option>
  <option value="1">With Prescription</option>  
</select>
        {/* <input type="text" id="prescription" name="prescription"  value={this.state.prescription} onChange={this.onChange_prescription} className="container" />  */}
           
          {/* <div className="table-header-5">Without Prescription</div> */}
          {/* <img
            className="feather-icon-chevron-3"
            alt="Feather icon chevron"
            src="../assets/img/feather-icon-chevron-right.svg"
          /> */}
        {/* </div> */}
        <buttom className="element-button-text" style={{left:"950px"}} onClick={this.saveProduct} >
        
          <div className="button-text-3">SAVE</div>
          
        </buttom>
        <div className="element-button-text-2" style={{left:"800px"}}>
        <NavLink to="/Products">
          <div className="button-text-4">CANCEL</div>
          </NavLink>
        </div>
      </div>
      <div className="overlap-wrapper">
        <div className="overlap-6">
          <div className="cards-header">
            <div className="portlet-title">Product Image</div>
            <img className="ic-expand-less" alt="Ic expand less" src="../assets/img/ic-expand-less-48px-1.svg" />
          </div>
          {/* <div className="table-header-6">Images &</div> */}
          <div className="cards-body">
            <input type="file" name="pic" onChange={this.handleFileUpload} />
            <img className="group-4" alt="Group" src="../assets/img/group-1561-1.png" />
          </div>
        </div>
      </div>
      {/* <div className="group-5">
        <div className="overlap-7">
          <div className="cards-header">
            <div className="portlet-title">Need Prescription</div>
            <img className="ic-expand-less" alt="Ic expand less" src="../assets/img/ic-expand-less-48px.svg" />
          </div>
           <div className="table-header-6"> <input type="text" id="prescription" name="prescription"  value={this.state.prescription} onChange={this.onChange_prescription} className="container" /> 
           
           </div> 
          
        </div>
      </div> */}
      
                        
      <div className="group-7">
      <div className={this.state.suc_cls}>{this.state.errors.message}</div>
        <div className="overlap-8">
       
          <img className="feather-icon" alt="Feather icon" src="../assets/img/feather-icon-maximize.svg" />
          <img className="feather-icon-type" alt="Feather icon type" src="../assets/img/feather-icon-type-1.svg" />
          <img className="feather-icon-hash" alt="Feather icon hash" src="../assets/img/feather-icon-hash.svg" />
          <div className="element">Product Name</div>
          <div className="element-2">Price</div>
          <div className="element-3">Brand Name</div>
          <img className="feather-icon-command" alt="Feather icon command" src="../assets/img/feather-icon-command.svg" />
          <div className="element-4">Quantity</div>
          <div className="element-5">Category</div>
          <div className="element-6">Sub Category</div>
          <img className="feather-icon-type-2" alt="Feather icon type" src="../assets/img/feather-icon-type-1.svg" />
          <img className="feather-icon-type-3" alt="Feather icon type" src="../assets/img/feather-icon-type.svg" />
          <img className="line" alt="Line" src="../assets/img/line-67.svg" />
          <img className="line-2" alt="Line" src="../assets/img/line-74.svg" />
          <img className="line-3" alt="Line" src="../assets/img/line-68.svg" />
          <img className="line-4" alt="Line" src="../assets/img/line-75.svg" />
          <img className="line-5" alt="Line" src="../assets/img/line-70.svg" />
          <img className="line-6" alt="Line" src="../assets/img/line-69.svg" />
          <div className="group-8">
            <div className="overlap-group-5">
              <div className="element-7">-</div>
              {/* <img className="container" alt="Container" src="../assets/img/container-5.svg" /> */}
              <input type="text" id="product_name"  value={this.state.product_name} onChange={this.onChange_product_name} className="container" />
                                    
            </div>
          </div>
          <div className="group-9">
            <div className="overlap-group-5">
              <div className="element-7">-</div>
              {/* <input type="text" id="category"  value={this.state.category} onChange={this.onChange_category} className="container" />
              <img
                className="feather-icon-chevron-4"
                alt="Feather icon chevron"
                src="../assets/img/feather-icon-chevron-right.svg"
              /> */}
               <select
                                      className="container"
                                      value={this.state.category} onChange={this.onChange_category}
                                    >
                                    <option key="none" value="none"> None </option>
                                      {this.state.categories.map(cat => (
                                        <option key={cat.cat_name} value={cat.cat_name+"__"+cat.cat_id}>
                                          {cat.cat_name}
                                        </option>
                                      ))}
                                    </select>
            </div>
          </div>
          <div className="group-10">
            <div className="overlap-group-5">
              <div className="element-7">-</div>
              <input type="text" id="sub_category"  value={this.state.sub_category} onChange={this.onChange_sub_category} className="container" />
              <img
                className="feather-icon-chevron-4"
                alt="Feather icon chevron"
                src="../assets/img/feather-icon-chevron-right.svg"
              />
            </div>
          </div>
          <div className="group-11">
            <div className="overlap-group-5">
              <div className="element-7">-</div>
             
              <input type="text" id="qty"  value={this.state.quantity} onChange={this.onChange_qty} className="container" />
            </div>
          </div>
          <div className="group-12">
            <div className="overlap-group-5">
              <div className="element-7">-</div>             
              {/* <input type="text" id="brand_name"  value={this.state.brand_name} onChange={this.onChange_brand_name} className="container" />
             */}
             <select
                                      className="container"
                                      value={this.state.brand_name} onChange={this.onChange_brand_name} 
                                    >
                                    <option key="none" value="none"> None </option>
                                      {this.state.brands.map(brand => (
                                        <option key={brand.brand_name} value={brand.brand_name+"__"+brand.brand_id}>
                                          {brand.brand_name}
                                        </option>
                                      ))}
                                    </select>
            
            </div>

          </div>
          <div className="group-13">
            <div className="overlap-group-5">
              <div className="element-7">-</div>
              <input type="text" id="price"  value={this.state.price} onChange={this.onChange_price} className="container" /> 
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>;
  }
}

export default AddProduct;



