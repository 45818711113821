import React from 'react';
import logo from './logo.svg';
// import './App.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/css/bootstrap.rtl.css'

import Login from "./components/Login"
import Signup from "./components/Signup"

import { BrowserRouter as Router, Routes, Route,  Navigate } from "react-router-dom";

 import Dashboard from "./components/dashboard"
 import Products from "./components/products"
 import Stores from "./components/stores"
 import Orders from "./components/orders"
 import Agents from "./components/agents" 
 import Customers from "./components/customers" 
 import Viewusers from "./components/Viewusers" 
 import AddProduct from "./components/addProduct"
 import AddImages from "./components/AddImages" 
 import ViewProduct from "./components/viewProduct" 
 import CreateProduct from "./components/CreateProduct" 
 import EditProduct from "./components/EditProduct" 
 import EditStore from "./components/editStore" 
 import EditAgent from "./components/editAgent"
 import AgentAcceptOrder from "./components/agentAcceptOrderPage"
 import StoreAcceptOrder from "./components/storeAcceptOrderPage"
 import Groups from "./components/Groups"
 import ViewGroup from "./components/viewGroup" 
 import EditGroup from "./components/EditGroup" 
 import CreateGroup from "./components/CreateGroup" 
 import Subgroups from "./components/Subgroups"
 import CreateSubgroup from "./components/CreateSubgroup" 
 import ViewSubgroup from "./components/viewSubgroup" 
 import EditSubgroup from "./components/EditSubgroup" 
 import Associateproducts from "./components/associateproducts"
 import Likecart from "./components/likeCart"
 import Savecart from "./components/saveCart"
 import Addcart from "./components/addCart"
 import Orderplaced from "./components/orderPlaced"
 import Orderdelivery from "./components/orderDelivery"
 import Orderassign from "./components/orderAssign"
 import Brands from "./components/Brands"
 import ViewBrand from "./components/viewBrand" 
 import EditBrand from "./components/EditBrand" 
 import CreateBrand from "./components/CreateBrand" 
 import Offers from "./components/Offers"
 import ViewOffer from "./components/viewOffer" 
 import EditOffer from "./components/EditOffer" 
 import CreateOffer from "./components/CreateOffer" 

 import Coupons from "./components/Coupons"
 import ViewCoupon from "./components/viewCoupon" 
 import EditCoupon from "./components/EditCoupon" 
 import CreateCoupon from "./components/CreateCoupon" 
 import RevenueReports from "./components/RevenueReports"
 import Reports from "./components/Reports"
 import Reports1 from "./components/Reports1"
 import Reports2 from "./components/Reports2"
 import { Helmet } from 'react-helmet';



 require('./assets/css/common.css');

 
 if (window.location.href.indexOf("viewProduct") > 0) require('./assets/css/viewproduct.css');
 if (window.location.href.indexOf("addProduct") > 0) require('./assets/css/product1.css');
 if (window.location.href.indexOf("editProduct") > 0 || window.location.href.indexOf("editStore") > 0|| window.location.href.indexOf("editAgent") > 0) require('./assets/css/editproduct.css');
 if (window.location.href.indexOf("Products") > 0 || window.location.href.indexOf("Stores")> 0 || window.location.href.indexOf("Agents") >0 || window.location.href.indexOf("Customers") || window.location.href.indexOf("Orders") ) require('./assets/css/product.css');
 if (window.location.href.indexOf("acceptOrder") > 0) require('./assets/css/acceptOrder.css');
 
     

class App extends React.Component {
  render() {
    return  <div className="App">
      <Helmet>
        <title>Green Grocery</title>
        <meta name="description" content="Global description for SEO." />
      </Helmet>
      {/* This is the alias of BrowserRouter i.e. Router */}
      <Router>
        <Routes>
          {/* This route is for home component 
          with exact path "/", in component props 
          we passes the imported component*/}
          <Route  path="/" element={<Login />} />
          <Route  path="/Login" element={<Login />} />
            
          {/* This route is for about component 
          with exact path "/about", in component 
          props we passes the imported component*/}
           <Route path="/Signup" element={<Signup />} />  
          <Route path="/Dashboard" element={<Dashboard />} />  
          <Route path="/Products" element={<Products />} />
          <Route path="/Stores" element={<Stores />} />
          <Route path="/Orders" element={<Orders />} />
          <Route path="/Agents" element={<Agents />} />
          <Route path="/Customers" element={<Customers />} />
          <Route path="/Viewusers" element={<Viewusers />} /> 
          <Route path="/addProducts" element={<AddProduct />} />
          <Route path="/addImages" element={<AddImages />} /> 
          <Route path="/viewProduct" element={<ViewProduct />} /> 
          <Route path="/createProduct" element={<CreateProduct />} /> 
          <Route path="/editProduct" element={<EditProduct />} /> 
          <Route path="/editStore" element={<EditStore />} /> 
          <Route path="/editAgent" element={<EditAgent />} />
          <Route path="/Groups" element={<Groups />} />
          <Route path="/viewGroup" element={<ViewGroup />} /> 
          <Route path="/editGroup" element={<EditGroup />} /> 
          <Route path="/createGroup" element={<CreateGroup />} /> 
          <Route path="/Subgroups" element={<Subgroups />} />
          <Route path="/createSubgroup" element={<CreateSubgroup />} /> 
          <Route path="/viewSubgroup" element={<ViewSubgroup />} />
          <Route path="/editSubgroup" element={<EditSubgroup />} /> 
          <Route path="/Associateproducts" element={<Associateproducts />} />
          <Route path="/Likecart" element={<Likecart />} />
          <Route path="/Savecart" element={<Savecart />} />
          <Route path="/Addcart" element={<Addcart />} />
          <Route path="/Orderplaced" element={<Orderplaced />} />
          <Route path="/Orderdelivery" element={<Orderdelivery />} />
          <Route path="/Orderassign" element={<Orderassign />} />
          <Route path="agent/acceptOrder/:agentId/:orderId" element={<AgentAcceptOrder />} />
          <Route path="store/acceptOrder/:agentId/:storeId" element={<StoreAcceptOrder />} />
          <Route path="/Brands" element={<Brands />} />
          <Route path="/viewBrand" element={<ViewBrand />} /> 
          <Route path="/editBrand" element={<EditBrand />} /> 
          <Route path="/createBrand" element={<CreateBrand />} /> 
          <Route path="/Offers" element={<Offers />} />
          <Route path="/viewOffer" element={<ViewOffer />} /> 
          <Route path="/editOffer" element={<EditOffer />} /> 
          <Route path="/createOffer" element={<CreateOffer />} /> 


          <Route path="/Coupons" element={<Coupons />} />
          <Route path="/viewCoupon" element={<ViewCoupon />} /> 
          <Route path="/editCoupon" element={<EditCoupon />} /> 
          <Route path="/createCoupon" element={<CreateCoupon />} /> 
          <Route path="/RevenueReports" element={<RevenueReports />} />
          <Route path="/Reports" element={<Reports />} />
          <Route path="/Reports1" element={<Reports1 />} />
          <Route path="/Reports2" element={<Reports2 />} />
        </Routes>
      </Router>
    
  </div>;
  }
}

export default App;
