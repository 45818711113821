import React from "react";
import { Link } from "react-router-dom";
import {useNavigate} from 'react-router-dom';
import { Button } from 'react-bootstrap'
import { Frame } from "../components/Frame";
import { FrameWrapper } from "../components/FrameWrapper";
import { FeatherIconLayout } from "../icons/FeatherIconLayout";
import { IcChevronLeft48Px } from "../icons/IcChevronLeft48Px";
import { IcChevronRight48Px } from "../icons/IcChevronRight48Px";
import { IcFirstPage48Px } from "../icons/IcFirstPage48Px";
import { IcLastPage48Px1 } from "../icons/IcLastPage48Px1";
import DataTable from 'react-data-table-component';
import { Modal } from 'react-responsive-modal';
import Header from "../components/Header"
import Footer from "../components/Footer"
import Sidebar from "../components/sidebar"
import InnerHeader from "../components/InnerHeader"
import {withRouter} from "../components/withRouter"
import axios from 'axios';
import '../assets/css/alertCss.css';
import '../assets/css/modal.css';
import SidebarOne from "../components/sidebar1"

//import '../assets/css/product.css';

class Agents extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      api_url:localStorage.getItem('api_url'),
        data:[],
        prescription:'no',search:'',suc_cls:'',
        errors:  {},pic:'',fileName:''
    };     
    
    this.onChange_search = this.onChange_search.bind(this); 
    this.handleButtonClick = this.handleButtonClick.bind(this);     
    this.handleImport = this.handleImport.bind(this);
    this.fileUploadclick = this.fileUploadclick.bind(this);
    this.handleDelete = this.handleDelete.bind(this); 
    this.handleFileUpload = this.handleFileUpload.bind(this); 
    this.handleImport = this.handleImport.bind(this);
    this.handleImport1 = this.handleImport1.bind(this);
    
  }
  handleImport1(){
    this.setState({openModal : true});
    return;

    
  }
  onCloseModal = ()=>{
    this.setState({openModal : false})
}
handleFileUpload(e){
  // this.setState({
  //     pic:e.target.files[0]
  // });
  document.getElementById("fileID").click();
}
fileUploadclick (e){
  this.setState({
        pic:e.target.files[0],
        fileName:e.target.files[0].name
        
    });
    console.log("file");
    console.log(this.state.pic);
    //document.getElementById("uploadForm").style.display = "block";
}
  onChange_search(e){
    //console.log("zd",e.target.value);
    this.setState({
        search:e.target.value
    });   
    //this.callgetstoresapi(e.target.value);  
  }
  onChange_prescription(e){
    //console.log("zd",e.target.value);
    this.setState({
        prescription:e.target.value
    });   
    //this.callgetstoresapi(e.target.value);  
  }
  handleDelete(e,agent_id){
    console.log(agent_id);
    const choice = window.confirm(
      "Are you sure you want to delete the Agent?"
    )
    if(choice){
      let errors = {};
      axios({
        method: 'get',
        url: this.state.api_url+'/deleteAgent/'+agent_id,               
        
      }).then(res => {
        const message = res.data.message;       
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'successMsg floatingAlerts1'
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'successMsghide'
                });
                this.props.navigate('/Agents');
              }, 2000);    
      });
    }
  }
  handleImport(){
    let errors = {};
    var agentimp = new FormData();
    agentimp.append('pic', this.state.pic); 

    axios({
        method: 'post',
        url: this.state.api_url+'/importAgents',   
        data: agentimp
             
    }).then(res => {
        const message = res.data.message;
       // console.log(res.data);
        errors["message"] = message;
        this.setState({
            errors: errors,
            suc_cls: 'successMsg floatingAlerts1',
            openModal : false
            });
            setTimeout(() => {
                this.setState({
                    suc_cls: 'successMsghide'
                });
                window.location.reload(true);
              }, 5000);    
        
        })
  }
  callgetstoresapi(){
    //console.log(this.state.prescription);    
    axios({
      method: 'get',
      url: this.state.api_url+'/admingetAgents',             
      
    }).then(res => {
      const data = res.data;
     // console.log(data);
      this.setState({                         
              data: data.agents,             
            });
    });
}
 handleButtonClick (e, id){
  e.preventDefault();
  //return <Link to='/viewProduct'/>;
  localStorage.setItem("agent_id",id);
  this.props.navigate('/editAgent');
  //this.props.history.push("/login");
 // console.log("Row Id", id);
};
  componentDidMount() {    
    this.callgetstoresapi(); 
}

  render() {
    const columns = [
      { name: 'Name', selector: row => row.agent_name },
      { name: 'Address', selector: row => row.address },
      { name: 'Phone number', selector: row => row.phoneno },
      { name: 'Email', selector: row => row.email },
      {
        name: 'Action',
        cell: (row) => (
          <div>
            
            <button
              className="btn btn-outline btn-xs"
              onClick={(e) => this.handleDelete(e, row.id)}
            >
              Delete
            </button>
          </div>
        ),
      },
    ];

    return (
      <div className="productsa">
        <Header />
        <SidebarOne />
        <InnerHeader />
        <hr />
        <p className="p_name_store">Agents</p>
        <div className="frame-3">
          <div className="frame-5" style={{ left: "5px" }}>
            <input
              type="text"
              placeholder="Search..."
              className="searchtxt"
              value={this.state.search}
              onChange={this.onChange_search}
            />
            <button type="submit" className="element-icon-button" style={{ padding: 0 }}>
              <img
                alt="Element icon button"
                src={require('../assets/img/4-icon-button-1-default-1-default.svg').default}
              />
            </button>
          </div>
          <button class="element-button-text-2" onClick={this.handleImport1}  style={{left:"985px"}}>
                    <img class="icons-ic-outline" src={require('../assets/img/importic.svg').default} />
                    <div class="button-text" style={{"color":"#7c3a8e"}}>IMPORT</div>
                    </button>
        </div>
        <div className="overlap-6">
          <div className={this.state.suc_cls}>{this.state.errors.message}</div>
          <div className="group-4">
            <div className="tables-default-wrapper">
              <div className="tables-default">
                <div className="overlap-group-4">
                  <div className="table-header-7">Product List With Prescription</div>
                </div>
              </div>
            </div>
            <DataTable columns={columns} data={this.state.data} />
          </div>
        </div>
              <Modal open={this.state.openModal} onClose={this.onCloseModal} id="fileupload">
                  <div class="container">
                  <h3>Import</h3>
                   <hr />
                  <div class="drop_box">
                  { (this.state.pic == '') ?
                  <div>
                  <header>
                      <h4 className="choose_import">Choose the file to be imported</h4>
                    </header>
                    <p className="choose_import1">Only xls,xlsx amd csv formats are supported</p>
                    <span className="choose_import2">Maximum upload file size is 5MB</span>
                    <br></br>
                  <input type="file" hidden accept=".csv" id="fileID" style={{display:"none"}} onChange={this.fileUploadclick}/>
                  <button class="btn choose_import3"  onClick={this.handleFileUpload}>Upload File</button> 
                   <br></br>
                    <a className="down_sample" href="javascript:;">Download sample template for import</a>
                  </div> : '' }
                  { (this.state.pic == '') ?
                  <form action="" id="uploadForm" method="post" style={{display:"none"}}> 
                  <div class="form">
                  <h4>{this.state.fileName}</h4>    
                  <button class="btn">Upload</button>
                  </div>
                  </form> : <form action="" id="uploadForm" method="post"> 
                  <div class="form">
                  <h4>{this.state.fileName}</h4>    
                  <button class="btn" type="button" onClick={this.handleImport}>Upload</button>
                  </div>
                  </form> }
                  </div>
                  </div>
              </Modal>
      </div>
    );
  }
}

export default withRouter(Agents);
