import React, { useState, useEffect } from "react";
import axios from 'axios';
import { withRouter } from "./withRouter";
import SidebarOne from "./sidebar1";
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';

function CreateOffer(props) {
  const [offer_name, setOfferName] = useState('');
  const [selectedProductIds, setSelectedProductIds] = useState([]);
  const [offer, setOffer] = useState('');
  const [products, setProducts] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();
  const [validationMessages, setValidationMessages] = useState({
    offer_name: "",
    offer: "",
    selectedProducts: ""
  });

  const api_url = localStorage.getItem('api_url');
  const admin_id = localStorage.getItem('admin_id');

  const addproductdetails = async (event) => {
    event.preventDefault(); // Prevent default form submission behavior

    if (validateForm()) {
      // Convert the array of product IDs to a comma-separated string
      const product_ids_string = selectedProductIds.join(',');

      const formData = {
        offer_name,
        product_id: product_ids_string,
        offer,
        admin_id,
      };

      try {
        const res = await axios.post(`${api_url}/addOffers`, formData);
        alert("Offer added successfully");
        navigate("/Offers", { replace: true });
      } catch (error) {
        console.error("Error adding offer:", error);
        alert("Error adding offer: " + error.message);
      }
    }
  };

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const res = await axios.get(`${api_url}/getProducts`);
        const data = res.data.products;
        setProducts(data);
      } catch (error) {
        console.error("Error fetching products:", error);
      }
    };
    fetchProducts();
  }, [api_url]);

  const handleProductSelect = (e) => {
    const { value, checked } = e.target;
    setSelectedProductIds(prevState =>
      checked ? [...prevState, value] : prevState.filter(id => id !== value)
    );
  };

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const filteredProducts = products
    .filter(product =>
      product.product_name.toLowerCase().includes(searchQuery.toLowerCase())
    )
    .sort((a, b) => {
      // Sort by whether the product name starts with the search query
      const aStartsWithQuery = a.product_name.toLowerCase().startsWith(searchQuery.toLowerCase());
      const bStartsWithQuery = b.product_name.toLowerCase().startsWith(searchQuery.toLowerCase());

      if (aStartsWithQuery && !bStartsWithQuery) return -1;
      if (!aStartsWithQuery && bStartsWithQuery) return 1;
      return 0;
    });

  const validateForm = () => {
    let isValid = true;
    const errors = {
      offer_name: "",
      offer: "",
      selectedProducts: ""
    };

    if (!offer_name.trim()) {
      errors.offer_name = "Offer name is required.";
      isValid = false;
    }

    if (selectedProductIds.length === 0) {
      errors.selectedProducts = "Please select at least one product.";
      isValid = false;
    }

    if (!offer) {
      errors.offer = "Offer percentage is required.";
      isValid = false;
    } else if (isNaN(offer) || parseFloat(offer) <= 0 || parseFloat(offer) > 100) {
      errors.offer = "Offer percentage must be a number between 1 and 100.";
      isValid = false;
    }

    setValidationMessages(errors);
    return isValid;
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>

      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Offers </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>

        <hr />

        <div className="product_detail">
          <form onSubmit={addproductdetails}>
            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Offer Name</p>
                <button type="submit" className="itme_img11">Save</button>
              </div>
              <input
                type="text"
                className="item_input_cat"
                name="offer_name"
                value={offer_name}
                onChange={(e) => setOfferName(e.target.value)}
              />
              {validationMessages.offer_name && <Alert severity="error">{validationMessages.offer_name}</Alert>}
            </div>
            <br />

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Select Products</p>
              </div>
              <input
                type="text"
                className="item_input_cat"
                placeholder="Search Product Name"
                value={searchQuery}
                onChange={handleSearch}
              />
              <br />
              {validationMessages.selectedProducts && <Alert severity="error">{validationMessages.selectedProducts}</Alert>}
              {searchQuery && (
                <div className="checkbox-group">
                  {filteredProducts.map((product) => (
                    <div key={product.id}>
                      <label>
                        <input
                          type="checkbox"
                          value={product.id}
                          onChange={handleProductSelect}
                        />
                        {product.product_name}
                      </label>
                    </div>
                  ))}
                </div>
              )}
            </div>

            <br />

            <div className="item_details">
              <div style={{ display: 'flex' }}>
                <p className="item_name">Offer Percentage</p>
              </div>
              <input
                type="number"
                className="item_input_cat"
                name="offer"
                value={offer}
                onChange={(e) => setOffer(e.target.value)}
              />
              {validationMessages.offer && <Alert severity="error">{validationMessages.offer}</Alert>}
            </div>
          </form>
        </div>
      </div>
    </div> 
  );
}

export default withRouter(CreateOffer);
