import React, { useState, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import SidebarOne from "./sidebar1";
import { withRouter } from "./withRouter";
import Alert from '@mui/material/Alert';

function CreateSubgroup(props) {
  const [sub_cat_name, setSub_catName] = useState("");
  const [cat_id, setCatId] = useState("");
  const [cat_status, setCatStatus] = useState("");
  const [file, setFile] = useState(null);
  const [data1, setData1] = useState([]);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const api_url = localStorage.getItem('api_url');

  const validateInputs = () => {
    const errors = {};
    if (!sub_cat_name) {
      errors.sub_cat_name = "Sub Category name is required";
    }
    if (!cat_id) {
      errors.cat_id = "Category is required";
    }
    if (!cat_status) {
      errors.cat_status = "Status is required";
    }
    if (!file) {
      errors.file = "Category image is required";
    }
    return errors;
  };

  const addproductdetails = () => {
    const validationErrors = validateInputs();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const admin_id = localStorage.getItem('admin_id');
    const formData = new FormData();
    formData.append("sub_cat_name", sub_cat_name);
    formData.append("cat_id", cat_id);
    formData.append("status", cat_status);
    formData.append("pic", file);
    formData.append("admin_id", admin_id);

    axios({
      method: "post",
      url: api_url + "/addsubcatdetails",
      data: formData
    })
      .then((res) => {
        alert("Sub Categories added successfully");
        navigate("/Subgroups", { replace: true });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Error adding product:", error);
      });
  };

  useEffect(() => {
    const fetchCategories = async () => {
      try {
        const res = await axios.get(api_url + '/getCategories');
        const data = res.data.categories;
        setData1(data);
      } catch (error) {
        console.error("Error fetching categories:", error);
      }
    };
    fetchCategories();
  }, []); // Empty dependency array to run the effect only once on mount

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div className="view_pro" style={{ display: 'flex' }}>
      <div>
        <SidebarOne />
      </div>
      <div className="pr_names">
        <div className="pro_names" style={{ display: 'flex' }}>
          <p className="p_name">Sub Categories </p>
          <p className="act_name" style={{ textAlign: 'left' }}>Active</p>
        </div>
        <hr></hr>
        <div className="product_detail">
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Sub Category Name</p>
              <button className="itme_img11" onClick={addproductdetails}>Save</button>
            </div>
            <input
              type="text"
              className="item_input_cat"
              value={sub_cat_name}
              onChange={(e) => setSub_catName(e.target.value)}
            />
            {errors.sub_cat_name && <Alert severity="error">{errors.sub_cat_name}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Name</p>
            </div>
            <select
              className="item_input_cat"
              value={cat_id}
              onChange={(e) => setCatId(e.target.value)}
            >
              <option value="">Select a Category Name</option>
              {data1.map((card) => (
                <option key={card.cat_id} value={card.cat_id}>
                  {card.cat_name}
                </option>
              ))}
            </select>
            {errors.cat_id && <Alert severity="error">{errors.cat_id}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Category Image</p>
            </div>
            <input type="file" onChange={handleFileChange} />
            {errors.file && <Alert severity="error">{errors.file}</Alert>}
          </div>
          <br />
          <div className="item_details">
            <div style={{ display: 'flex' }}>
              <p className="item_name">Status</p>
            </div>
            <select
              className="item_input_cat"
              value={cat_status}
              onChange={(e) => setCatStatus(e.target.value)}
            >
              <option value="">select status</option>
              <option value="active">Active</option>
              <option value="inactive">In Active</option>
            </select>
            {errors.cat_status && <Alert severity="error">{errors.cat_status}</Alert>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default withRouter(CreateSubgroup);
