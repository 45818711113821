import React from "react";
import { Link, NavLink } from 'react-router-dom';
import { Frame } from "../components/Frame";
import Alert from '@mui/material/Alert';
import DataTable from 'react-data-table-component';
import Header from "../components/Header";
import Footer from "../components/Footer";
import Sidebar from "../components/sidebar";
import InnerHeader from "../components/InnerHeader";
import axios from 'axios';
import { withRouter } from "../components/withRouter";
import SidebarOne from "../components/sidebar1";
import ProductHierarchy from "../components/ProductHierarchy";

class ViewProduct extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      product: {},
      productattribute: {},
      productspec: [],
      product_id: localStorage.getItem("product_id"),
      showAlert: false,
      errors: {},
      suc_cls: '',
      mediaVisible: false,
      specsVisible: false,
      hierarchyVisible: false,
      attributesVisible: false
    };

    this.handleDelete = this.handleDelete.bind(this);
  }
  toggleVisibility = (key) => {
    this.setState((prevState) => ({
      [key]: !prevState[key]
    }));
  };
  handleDelete() {
    const choice = window.confirm(
      "Are you sure you want to delete the product?"
    )
    if (choice) {
      axios({
        method: 'get',
        url: this.state.api_url + '/deleteProduct/' + this.state.product_id,
      }).then(res => {
        const message = res.data.message;
        this.setState({
          errors: { message },
          suc_cls: 'successMsg floatingAlerts1'
        });
        setTimeout(() => {
          this.setState({
            suc_cls: 'successMsghide'
          });
          this.props.history.push('/Products');
        }, 2000);
      });
    }
  }

  componentDidMount() {
    const { api_url, product_id } = this.state;
    axios({
      method: 'get',
      url: `${api_url}/admingetProduct/${product_id}`,
    }).then(res => {
      const data = res.data;
      this.setState({
        product: data.product
      });
      this.setState({
        productattribute: data.product_attribute
      });
      this.setState({
        productspec: data.product_spec
      });
    });
  }


  moveeditproduct = () => {
    this.props.navigate("/editProduct");
  };

  render() {
    const { product } = this.state;

    return (
      <div className="view_pro" style={{'display':'flex'}}>
        <div>
        <SidebarOne />
        </div>
        
      <div className="pr_names">
        <div className="pro_names" style={{'display':'flex'}}> 
            <p className="p_name">Products </p>
            <p className="act_name" style={{'textAlign':'left'}}>Active</p>
        </div>

        <hr></hr>
      <div className="product_detail">
        <div  style={{'display':'flex'}}>

            {/* <div className="item_details">
              <div style={{'display':'flex'}}>
                <img className="itme_img" src={require("../assets/images/hash.png")}/>
                <p className="item_name">Item ID</p>
               </div>
                <p className="pro_de"><b>{product.item_id}</b></p>
            </div>


            <div className="stock_details">
                <div style={{'display':'flex'}}>
                  <div className="vl"></div>
                     <div className="itme_d">
                          <div  style={{'display':'flex'}}>
                                <img className="itme_img" src={require("../assets/images/command.png")}/>
                                <p className="item_name">Stock Code (SKU)</p>
                          </div>
                          <p className="pro_de"><b>{product.item_sku}</b></p>
                      </div>
                </div>
            </div>

            <div className="barcode_details">
            <div style={{'display':'flex'}}>
                  <div className="vl"></div>
                     <div className="itme_d">
              <div style={{'display':'flex'}}>
               <img className="itme_img" src={require("../assets/images/maximize.png")}/>
               <p className="item_name">Barcode Type</p>
               </div>
               <p className="pro_de"><b>{product.barcode_type}</b></p>
               </div>
               </div>
            </div>

            <div className="barvalue_details">
            <div style={{'display':'flex'}}>
                  <div className="vl"></div>
                     <div className="itme_d">
              <div style={{'display':'flex'}}>
                  <img className="itme_img" src={require("../assets/images/barcode.png")}/>
                  <p className="item_name">Barcode Value</p>
               </div>
               <p className="pro_de"><b>{product.barcode_value}</b></p>
            </div>
            </div>
            </div>
            <div className="img_detils">
            <img className="itme_img" onClick={this.moveeditproduct} src={require("../assets/images/edit.png")} />

            </div> */}
            
        </div>

        <hr></hr>

            <div  style={{'display':'flex'}}>

                <div className="item_details">
                  <div style={{'display':'flex'}}>
                    <img className="itme_img" src={require("../assets/images/type.png")}/>
                    <p className="item_name">Item Title</p>
                  </div>
                    <p className="pro_de"><b>{product.product_name}</b></p>
                </div>
                <div className="tax_ref_details">
                <div style={{'display':'flex'}}>
                  <div className="vl"></div>
                     <div className="itme_d">
                  <div  style={{'display':'flex'}}>
                  <img className="itme_img" src={require("../assets/images/hash.png")}/>
                  <p className="item_name">Tax Reference Code</p>
                  </div>
                  <p className="pro_de"><b>{product.tax_reference}</b></p>
                </div>
                </div>
                </div>
            </div> 

            <hr></hr>

          <div style={{ display: 'flex' }}>

                  <div className="item_details">
                    <div style={{ display: 'flex' }}>
                      <img className="itme_img" src={require("../assets/images/type.png")} />
                      <p className="item_name">Item Price</p>
                    </div>
                    <p className="pro_de"><b>₹{product.price}.00</b></p>
                  </div>
                  <div className="tax_ref_details1">
                    <div style={{ display: 'flex' }}>
                      <div className="vl"></div>
                      <div className="itme_d">
                        <div style={{ display: 'flex' }}>
                          <img className="itme_img" src={require("../assets/images/hash.png")} />
                          <p className="item_name">Item Quantity</p>
                        </div>
                        <p className="pro_de"><b>{product.qty}</b></p>
                      </div>
                    </div>
                  </div>


                  <div className="tax_ref_details1">
                    <div style={{ display: 'flex' }}>
                      <div className="vl"></div>
                      <div className="itme_d">
                        <div style={{ display: 'flex' }}>
                          <img className="itme_img" src={require("../assets/images/hash.png")} />
                          <p className="item_name">Brand Name</p>
                        </div>
                        <p className="pro_de"><b>{product.brand_name}</b></p>
                      </div>
                    </div>
                  </div>
          </div>
          </div>
        <br></br>



<div className="media">
  <div className="pro_de_ref" >
    <p><b>Media</b></p>
    
  <hr />
  <br></br>
  <p className="img_and_vid"><b>Images & Videos</b></p>
   <img className="product_imgs" src={product.product_image} />
    
  </div>
  
</div>

        <br></br>
        {/* <div className="product_sepc pro_de_ref" >
          <p><b>Product Specifications</b></p>
          <hr />
          <p><b>General</b></p>
          <hr />
          <table style={{ borderCollapse: 'collapse', width: '100%','margin-top': '-17px' }}>
          <thead>
              <tr>
                <th className="tab_itmes">Label Name</th>
                <th className="tab_itmes">Value</th>
              </tr>
            </thead>
            <tbody>
              {this.state.productspec.map((attribute) => (
                <tr key={attribute._id}>
                  <td className="tab_itmes">{attribute.spec_key}</td>
                  <td className="tab_itmes">{attribute.spec_value}</td>
                </tr>
              ))}
            </tbody>
            
          </table>
        </div> */}
        <br></br>
        <div className="item_hi pro_de_ref" >
          <p><b>Item Hierarchy</b></p>
         <ProductHierarchy/>
        </div>
        <br></br>
        <div className="attributes pro_de_ref">
          <p><b>Attributes</b></p>
          <hr/>
          <table style={{ borderCollapse: 'collapse', width: '100%','margin-top': '-17px' }}>
           <thead>
              <tr>
              <th className="tab_itmes">
                Container Type   :    {this.state.productattribute.container_type}
              </th>
              
              <th className="tab_itmes">
                Nutrient  :    {this.state.productattribute.nutrient}
              </th>
              </tr>
              <tr>
              <th className="tab_itmes">
                weight  :     {this.state.productattribute.weight}
              </th>
              
              <th className="tab_itmes">
                Ingredients  :   {this.state.productattribute.ingredients}
              </th>
              </tr>
              <tr>
              <th className="tab_itmes">
                Organic Certified : {this.state.productattribute.organiccertified}
              </th>
              
              <th className="tab_itmes">
                Expiry Date : {this.state.productattribute.expirydate}
              </th>
              </tr>
              </thead>
            
          </table>
        </div>
        <br></br>
      </div>
      
       
      </div>
    );
  }
}

export default withRouter(ViewProduct);
