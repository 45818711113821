import React from "react";
import { Link } from "react-router-dom";
import { withRouter } from "./withRouter";
import axios from 'axios';
import DataTable from 'react-data-table-component';
import Header from "./Header";
import Footer from "./Footer";
import SidebarOne from "./sidebar1";
import InnerHeader from "./InnerHeader";
import RevenueReports1 from "./RevenueReports1";

class Reports1 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      api_url: localStorage.getItem('api_url'),
      data: [],
      prescription: 'no',
      search: ''
    };
    
  }

  componentDidMount() {
  }




  handleButtonClick = (e, id) => {
    e.preventDefault();
    localStorage.setItem("product_id", id);
    this.props.history.push('/viewProduct');
  };

  render() {
   
    return (
      <div className="productsa">
        <SidebarOne />
        <InnerHeader />
        <hr></hr>
        <div>
          
          <RevenueReports1 />
        </div>
        
        
      </div>
    );
  }
}

export default withRouter(Reports1);
